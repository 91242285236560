import axios from 'axios'
import router from '../../../router'

export default {
  cleanHistory(context) {
    axios.delete(context.rootGetters.server + "/api/user/history", context.rootGetters.headers).then(() => {
      context.getters["user/history"] = [];
    });
  },
  load(context) {
    return new Promise((resolve, reject) => {
      axios.get(context.rootGetters.server + "/api/user/login", context.rootGetters.headers).then((res) => {
        context.commit("load", res.data);
        context.rootState["player"].shuffle = res.data.player.shuffle;
        context.rootState["player"].repeatType = res.data.player.repeat;
        resolve();
      }).catch(err => {
        context.commit("resetToken");
        reject(err);
      });
    });
  },
  login(context, user) {
    return new Promise((resolve, reject) => {
      axios.post(context.rootGetters.server + "/api/user/login", {
        username: user.username,
        password: user.password,
      })
        .then((res) => {
          context.commit("load", res.data);
          context.dispatch("setToken", res.data.token).then(() => {
            resolve();
          });
        })
        .catch((err) => {
          context.commit("resetToken");
          reject(err.response);
        });
    });
  },
  logout(context) {
    context.commit("resetToken");
    localStorage.setItem("token", "");
    router.push("/login")

    this.dispatch("albums/clear");
    this.dispatch("artists/clear");
    this.dispatch("boxes/clear");
    this.dispatch("radios/clear");

    this.commit("tracks/resetSelectedTrack");
    this.commit("radios/resetSelectedRadio");
    this.commit("videos/resetSelectedVideo");
  },
  toggleFavourite(context, item) {
    let fav = context.state.favourites;
    let f = fav.find(f => f.itemId == item.itemId);
    if (f == undefined) {
      axios.post(context.rootGetters.server + "/api/user/favourites", item, context.rootGetters.headers);
      fav.push(item);
    } else {
      axios.delete(context.rootGetters.server + "/api/user/favourites?itemId=" + item.itemId, context.rootGetters.headers);
      fav.splice(fav.indexOf(f), 1);
    }
  },
  setToken(context, token) {
    return new Promise((resolve) => {
      localStorage.setItem("token", token);
      context.commit("setToken", token);
      resolve();
    });
  },
  saveHistoryItem(context, item) {
    if (context.state._id == -1) {
      return;
    }
    axios
      .post(context.rootGetters.server + "/api/user/history", item, context.rootGetters.headers)
      .then((res) => {
        context.commit("setHistory", res.data);
      });
  },
  saveProgress(context, item) {
    if (context.state._id == -1) {
      return;
    }
    axios
      .post(context.rootGetters.server + "/api/user/progress", item, context.rootGetters.headers);
  },
  getProgress(context, parent) {
    return new Promise((resolve) => {
      if (context.state._id == -1) {
        resolve();
      } else {
        axios
          .get(context.rootGetters.server + "/api/user/progress/" + parent._id, context.rootGetters.headers)
          .then((res) => {
            parent.progress = res.data;
            resolve();
          });
      }
    });
  },
  resetProgress(context, parentId) {
    if (context.state._id == -1) {
      return;
    }
    axios
      .delete(context.rootGetters.server + "/api/user/progress/" + parentId, context.rootGetters.headers);
  },
  savePlayerSettings(context) {
    let body = {
      repeat: context.rootGetters["player/repeatType"],
      shuffle: context.rootGetters["player/shuffle"]
    };
    axios
      .put(context.rootGetters.server + "/api/user/settings", body, context.rootGetters.headers);
  },
  update(context, user) {
    return new Promise((resolve, reject) => {
      axios.post(
        context.rootGetters.server + "/api/user/update",
        {
          oldPassword: user.oldPass,
          newPassword: user.newPass,
        },
        context.rootGetters.headers
      ).then((res) => {
        resolve(res);
      }).catch((err) => {
        reject(err);
      });
    });
  },
  updateConfig(context) {
    axios.post(context.rootGetters.server + "/api/user/update",
      {
        mobile_bpm: context.getters["settings"].mobile_bpm,
        desktop_bpm: context.getters["settings"].desktop_bpm,
        video_lang: context.getters["settings"].video_lang,
        video_quality: context.getters["settings"].video_quality,
        fullname: context.getters.user.fullname,
      },
      context.rootGetters.headers
    );
  },
  useGuestAccount(context) {
    return new Promise((resolve) => {
      context.commit("setGuestAccount");
      resolve();
    })
  }
}