<template>
  <DialogBase
    ref="dialogWindow"
    title="Server Settings"
    @accept="accept"
    @closing="closing"
    :closeOnButtonClick="false"
    :closeOnFocusLost="false"
    buttonText="Accept"
    buttonClass="accept"
  >
    <h4 class="ma-top">General</h4>
    <hr class="ma-right ma-left" />
    <ul class="ma">
      <li>
        <input type="checkbox" id="allowGuests" v-model="allows.guests" />
        <label for="allowGuests">Allow Guests</label>
      </li>
      <li>
        <input
          type="checkbox"
          id="allowRegistrations"
          v-model="allows.register"
        />
        <label for="allowRegistrations">Allow Registration</label>
      </li>
    </ul>
    <h4 class="ma-top">Actions</h4>
    <hr class="ma-right ma-left" />
    <div><button class="danger ma" @click="resetRedisCache">Reset Redis Cache</button></div>

    <h4 class="ma-top">Accepted Domains</h4>
    <hr class="ma-right ma-left" />
    <ul class="ma-top ma-left">
      <li v-for="(domain, i) in domains.const" :key="i">
        <span> {{ domain }}</span>
      </li>
      <li v-for="(domain, i) in domains.dynamic" :key="i">
        <span> {{ domain }}</span
        ><button class="flat danger" @click="removeDomain(domain)">
          <awesome-icon icon="trash-alt" />
        </button>
      </li>
    </ul>
    <input
      type="url"
      class="ma"
      v-model="newDomain"
      placeholder="Add new domain"
      @change="addDomain"
    />
  </DialogBase>
</template>
<script>
export default {
  data() {
    return {
      allows: { guests: false, register: false },
      domains: {},
      newDomain: "",
    };
  },
  methods: {
    accept() {
      this.$store.dispatch("saveSystemAllows", this.allows);
      this.$store.dispatch("saveSystemDomains", this.domains);
      this.$refs.dialogWindow.close();
    },
    addDomain(e) {
      if (e.srcElement.checkValidity()) {
        this.domains.dynamic.push(this.newDomain);
        this.newDomain = "";
      }
    },
    closing() {},
    open() {
      this.allows = { ...this.$store.getters.serverConfig.allows };
      this.$store.dispatch("loadSystemDomains").then((domains) => {
        this.domains = { ...domains };
      });
      this.$refs.dialogWindow.open();
    },
    removeDomain(domain) {
      this.domains.dynamic.splice(this.domains.dynamic.indexOf(domain), 1);
    },
    resetRedisCache(){
      this.$store.dispatch("resetRedisCache");
    }
  },
};
</script>
<style scoped>
h4 {
  margin-left: 12px;
}
li {
  display: flex;
  flex-direction: row;
}
li:not(:last-child) {
  display: flex;
  margin-bottom: 12px;
}
li input {
  margin-right: 4px;
}
li span {
  flex-grow: 1;
}
li button {
  opacity: 0.25;
}
li button:hover {
  opacity: 1;
}
input[type="url"] {
  margin-right: 24px;
}
</style>