import axios from 'axios'
import router from '../../../router'

export default {
  play(context, video) {
    context.commit("selectVideo", video);
    if (context.rootGetters.routerQuery.play != video._id && context.rootGetters.routerPath != "/share") {
      router.push("/boxes?id=" + video.parent._id + "&play=" + video._id);
    }
  },
  playContainer(context, container) {
    context.dispatch("play", container.videos[0]);
  },
  playNextTo(context, video) {
    context.commit("resetSelectedVideo");
    let currentIndex = video.parent.videos.indexOf(video);
    if (currentIndex < video.parent.videos.length - 1) {
      context.dispatch("play", video.parent.videos[currentIndex + 1]);
    } else {
      this.dispatch("user/resetProgress", video.parent._id);
    }
  },
  convertNextTo(context, payload) {
    let currentIndex = payload.video.parent.videos.indexOf(payload.video);
    if (currentIndex < payload.video.parent.videos.length - 1) {
      let nextVideo = payload.video.parent.videos[currentIndex + 1]
      axios.get(context.rootGetters.server + "/api/videos/" + nextVideo._id + "/convert/" + (context.rootGetters["user/settings"].video_quality || "realtime") + "/" + payload.langIndex).then(() => {
        console.log("Pre Convert started for: " + nextVideo.title);
      });
    }
  },
  resetSelectedVideo(context) {
    if (!context.getters.selectedVideo._id) {
      return;
    }
    let box_id = context.getters.selectedVideo.parent._id;
    context.commit("resetSelectedVideo");
    if (context.rootGetters.routerQuery.play)
      router.push("/boxes?id=" + box_id);
  },
  upload(context, form) {
    let h = context.rootGetters.headers;
    h.headers["content-type"] = "multipart/form-data";
    return new Promise((resolve, reject) => {
      axios
        .post(context.rootGetters.server + "/api/videos", form, h)
        .then(() => {
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  loadMostViewed(context) {
    axios.get(context.rootGetters.server + "/api/videos/most_viewed", context.rootGetters.headers)
      .then((res) => {
        context.commit("setMostViewed", res.data);
      });
  },
}