import axios from 'axios'

export default {
  checkIfInstanceIsNew(context) {
    return new Promise((resolve) => {
      axios.get(context.rootGetters.server + "/api/system/setup").then((res) => {
        if (res.status == 200) {
          resolve(true);
        } else {
          resolve(false);
        }
      });
    });
  },
  loadClientConfigs(context) {
    return new Promise((resolve) => {
      axios.get("./config.json").then((res) => {
        context.state.clientConfig.server = res.data.backend;
        context.state.clientConfig.isElectron = navigator.userAgent.toLowerCase().includes("electron");
        if (window.location.hostname.includes("localhost") && !context.getters.isElectron) {
          context.state.clientConfig.server = res.data.backend_dev;
        }
        resolve();
      });
    });
  },
  loadSystemDomains(context) {
    return new Promise((resolve) => {
      axios.get(context.rootGetters.server + "/api/system/domains", context.rootGetters.headers).then((res) => {
        context.commit("setSystemConfigDomains", res.data);
        resolve(res.data);
      });
    })
  },
  loadSystemSettings(context) {
    axios.get(context.rootGetters.server + "/api/system").then((res) => {
      context.commit("setSystemSettings", res.data);
    });
  },
  loadServerInfo(context) {
    axios.get(context.rootGetters.server + "/api/info").then((res) => {
      context.commit("setServerInfo", res.data);
    });
  },
  resetViewMenu(context) {
    context.commit("resetViewMenu");
  },
  setNewBackend(context, backend) {
    axios.post("/settings", { backend: backend }).then(() => {
      context.commit("setServer", backend);
    });
  },
  setViewMenu(context, menuItems) {
    context.commit("setViewMenu", menuItems);
  },
  startScanningMusic(context) {
    if (!context.state.serverStatus.scanning_music) {
      axios.post(context.rootGetters.server + "/api/scan/music", {}, context.rootGetters.headers).then(() => {
        context.state.serverStatus.scanning_music = true;
      });
    }
  },
  startScanningVideos(context) {
    if (!context.state.serverStatus.scanning_video) {
      axios.post(context.rootGetters.server + "/api/scan/video", {}, context.rootGetters.headers).then(() => {
        context.state.serverStatus.scanning_video = true;
      });
    }
  },
  saveSystemAllows(context, allows) {
    axios
      .post(context.rootGetters.server + "/api/system", allows, context.rootGetters.headers)
      .then((res) => {
        if (res.status == 200) {
          context.commit("setServerConfigAllows", allows);
        }
      });
  },
  saveSystemDomains(context, domains) {
    axios
      .post(context.rootGetters.server + "/api/system/domains", domains.dynamic, context.rootGetters.headers)
      .then((res) => {
        if (res.status == 200) {
          context.commit("setSystemConfigDomains", domains);
        }
      });
  },
  resetRedisCache(context) {
    axios.post(context.rootGetters.server + "/api/system/reset/redis", {}, context.rootGetters.headers);
    context.dispatch("addPoUp", { title: "Cache", message: "Cache was reseted", type: "info" })
  },
  addPoUp(context, item) {
    context.commit("addPopUp", item);
  },
  removePopUp(context, item) {
    context.commit("removePopUp", item);
  }
}