export default {
  setResult(state, result) {
    result.forEach(item => {
      if (!item.covers) {
        item.covers = {}
      }
    });
    state.collection = result || [];
  },
  setTerm(state, term) {
    state.term = term.trim();
    if (state.term == "") {
      state.collection = [];
    }
  }
}