<template>
  <div ref="share" id="share">
    <AlbumContent ref="album" v-if="type == 'album'" />
    <BoxContent ref="box" v-if="type == 'box'" />
  </div>
</template>

<script>
import AlbumContent from "../components/AlbumContent.vue";
import BoxContent from "../components/BoxContent.vue";

export default {
  name: "ShareView",
  data() {
    return {
      type: "",
    };
  },
  mounted() {
    if (this.server != "none") {
      this.loadShare();
    }
  },
  methods: {
    loadShare() {
      if (this.$route.query.id) {
        this.$store.dispatch("share/get", this.$route.query.id).then((res) => {
          if (!res._id) {
            this.$router.replace("/login");
            return;
          }
          this.type = res.object.type;
          this.$nextTick(() => {
            switch (this.type) {
              case "album":
                this.$refs.album.show(res.object);
                break;
              case "box":
                this.$refs.box.show(res.object);
                break;
            }
          });
        });
      } else {
        this.$router.replace("/login");
      }
    },
  },
  computed: {
    server() {
      return this.$store.getters.server;
    },
  },
  components: {
    AlbumContent,
    BoxContent,
  },
  watch: {
    server() {
      this.loadShare();
    },
    "$route.query.id": function () {
      this.loadShare();
    },
  },
};
</script>

<style scoped>
#share {
  flex-direction: column;
}
</style>