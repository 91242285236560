<template>
  <div
    :id="item._id"
    class="container artist"
    @click="click"
    :title="item.name"
    v-if="type == 'default'"
  >
    <img class="artistCover shadow" :src="cover" />
    <p class="artistName componentTitle">
      {{ item.name }}
    </p>
  </div>
  <div v-else-if="type == 'line'" class="album-line flex-row" @click="click">
    <img class="artistCover shadow line" :src="cover" />
    <p class="artistName line">
      <b> {{ item.name }}</b>
    </p>
  </div>
</template>
<script>
import BaseCollection from "../mixins/BaseCollection";

export default {
  name: "ArtistItem",
  mixins: [BaseCollection],
  props: {
    type: {
      type: String,
      default: "default",
    },
  },
  mounted() {
    if (this.$route.query.id == this.item._id && this.item.albums) {
      this.$nextTick(() => {
        this.scrollFunction();
        this.$store.dispatch("artists/selectArtist", this.item);
      });
    }
  },
  methods: {
    click() {
      if (this.$route.path != "/artists") {
        this.$store.dispatch("artists/loadArtist", this.item._id);
        this.$router.push("/artists?id=" + this.item._id);
      } else {
        this.scrollFunction();
      }
    },
  },
  computed: {
    cover() {
      let res = "/static/icons/dummy/artist.svg";
      if (
        this.type == "default" &&
        this.item.covers &&
        this.item.covers.cover256
      ) {
        res = this.item.covers.cover256;
      } else if (
        this.type == "line" &&
        this.item.covers &&
        this.item.covers.cover128
      ) {
        res = this.item.covers.cover128;
      }
      return res;
    },
  },
};
</script>

<style scoped>
.artistCover.line {
  width: 128px;
  height: 64px;
}
.artistName.line {
  align-self: center;
  margin-left: 8px;
}
</style>
