export default {
    collection(state) {
        return state.collection;
    },
    favourites(state, getters, rootState, rootGetters) {
        return state.collection.filter(f => rootGetters["user/favourites"].map(m => m.itemId).indexOf(f._id) > -1);
    },
    nextAlbum(state) {
        let currentIndex = state.collection.indexOf(state.selectedAlbum);
        let nextAlbum = {};
        if (state.collection.length > currentIndex + 1) {
            nextAlbum = state.collection[currentIndex + 1];
        }
        return nextAlbum;
    },
    prevAlbum(state) {
        let currentIndex = state.collection.indexOf(state.selectedAlbum);
        let prevAlbum = {};
        if (currentIndex > 0) {
            prevAlbum = state.collection[currentIndex - 1];
        }
        return prevAlbum;
    },
    selectedAlbum(state) {
        return state.selectedAlbum;
    },
    loading(state) {
        return state.loading;
    },
    eos(state) {
        return state.eos;
    },
    newest(state) {
        return state.newest;
    },
    randomCovers(state) {
        return state.randomCovers;
    }
}