<template>
  <div class="dropdown">
    <div ref="dropdownActivator" class="dropdown-activator" @click="click">
      <slot />
    </div>
    <div v-if="open" class="dropdown-background" ref="dropdownBackground" @click="bgClicked">
      <div
        ref="dropdownContent"
        class="dropdown-content"
        :style="'top: ' + top + 'px; left: ' + left + 'px;'"
      >
        <slot name="dropdown-content" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    closeOnClick: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      open: false,
      top: 0,
      left: 0
    };
  },
  methods: {
    click() {
      let a = this.$refs.dropdownActivator.getBoundingClientRect();
      let x = a.left;
      let y = a.top + a.height;
      let h = window.innerHeight;
      let w = window.innerWidth;

      this.open = !this.open;
      if (this.open) {
        this.$nextTick(() => {
          let b = this.$refs.dropdownContent.getBoundingClientRect();
          if (x + b.width > w) {
            x -= b.width - a.width;
          }
          if (y + b.height > h) {
            y -= b.height + a.height;
          }
          this.left = x;
          this.top = y;
        });
      }
    },
    bgClicked(e) {
      if (!this.closeOnClick && e.srcElement != this.$refs.dropdownBackground) {
        return;
      }
      this.open = false;
    }
  }
};
</script>