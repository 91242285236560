<template>
  <div id="setup">
    <img id="loginLogo" src="static/icon_64_flat.svg" />
    <h1>WebPlay Setup</h1>
    <form id="setupForm" @submit.prevent="setup" method="POST">
      <p>
        <input
          ref="username"
          name="username"
          type="text"
          placeholder="username"
          id="username"
        />
      </p>
      <p>
        <input
          ref="password"
          name="password"
          type="password"
          placeholder="password"
          id="password"
        />
      </p>
      <p v-if="$store.getters.isElectron">
        <input
          ref="backend"
          name="backend"
          type="url"
          placeholder="backend"
          id="backend"
          v-model.lazy="server"
          @change="backendChanged"
        />
      </p>
      <button class="flat" action="submit">Create admin account</button>
    </form>
    <p>{{ message }}</p>
  </div>
</template>

<script>
export default {
  name: "SetupView",
  data() {
    return {
      message: "",
    };
  },
  mounted() {
    if (this.server != "none") {
      this.checkIfNewBackend();
    }
  },
  methods: {
    setup() {
      let user = {
        username: this.$refs.username.value,
        password: this.$refs.password.value,
      };
      this.$store.dispatch("system/createInstanceAccess", user);
    },
    checkIfNewBackend() {
      this.$store.dispatch("checkIfInstanceIsNew").then((res) => {
        if (!res) {
          this.$router.replace("login");
        }
      });
    },
    backendChanged() {
      this.$http
        .post("/settings", { backend: this.$refs.backend.value })
        .then(() => {
          this.$store.state.server = this.$refs.backend.value;
        });
    },
  },
  computed: {
    server() {
      return this.$store.getters.server;
    },
  },
  watch: {
    server() {
      this.checkIfNewBackend();
    },
  },
};
</script>