<template>
  <div
    ref="search"
    id="search"
    @scroll="checkCoverVisibility"
    @resize="checkCoverVisibility"
  >
    <template v-for="item in collection">
      <AlbumItem
        class="ma"
        v-if="item.type == 'album'"
        :item="item"
        :key="item._id"
      />
      <ArtistItem
        class="ma"
        v-if="item.type == 'artist'"
        :item="item"
        :key="item._id"
      />
      <BoxItem
        class="ma"
        v-if="item.type == 'box'"
        :item="item"
        :key="item._id"
      />
      <RadioItem
        class="ma"
        v-if="item.type == 'radio'"
        :item="item"
        :key="item._id"
      />
      <TrackItem v-if="item.type == 'track'" :track="item" :key="item._id" />
      <VideoItem v-if="item.type == 'video'" :video="item" :key="item._id" />
    </template>
    <MessageScreen
      v-if="collection.length == 0"
      title="No Results"
      subtitle="Try an other search term…"
      icon="search"
    />
  </div>
</template>
<script>
import TrackItem from "../components/Track";
import VideoItem from "../components/Video";
import RadioItem from "../components/Radio";
import { mapGetters } from "vuex";

export default {
  name: "SearchView",
  data() {
    return {
      scrollPosition: 0,
    };
  },
  mounted() {
    if (this.$route.query.q) {
      this.$store.commit("search/setTerm", this.$route.query.q);
    }
    this.$store.dispatch("setViewMenu", this.viewMenu);
  },
  methods: {
    checkCoverVisibility() {
      this.scrollPosition = this.$refs.search.scrollTop;
    },
    searchRequest(q) {
      this.$store.dispatch("search/search", q);
    },
  },
  computed: {
    ...mapGetters({
      term: "search/term",
      collection: "search/collection",
      search: "search/collection",
    }),
  },
  watch: {
    "$route.path": function (newVal) {
      if (newVal == "/search") {
        this.$nextTick(() => {
          this.$refs.search.scrollTop = this.scrollPosition;
          this.$store.dispatch("setViewMenu", this.viewMenu);
        });
      }
    },
    term(newVal) {
      if (newVal && newVal != "") {
        this.searchRequest(newVal);
      }
    },
  },
  components: {
    VideoItem,
    RadioItem,
    TrackItem,
  },
};
</script>
