<template>
  <DialogBase ref="dialogWindow" title="Settings" @closed="closed">
    <div id="settingsBody">
      <table class="configValues">
        <tr>
          <td colspan="2">
            <h4>Configuration</h4>
          </td>
        </tr>
        <tr>
          <td>Backend</td>
          <td>
            <input v-model.lazy="backend" @change="backendChanged" />
          </td>
        </tr>
      </table>
    </div>
  </DialogBase>
</template>
<script>
export default {
  name: "SettingsDialog",
  data() {
    return {
      backend: "",
      newBackend: false,
    };
  },
  methods: {
    open() {
      this.$refs.dialogWindow.open();
      this.backend = this.$store.state.server;
    },
    backendChanged() {
      this.$store.dispatch("setNewBackend", this.backend);
      this.newBackend = true;
    },
    closed() {
      if (this.newBackend) {
        window.location.href = "/";
      }
    },
  },
};
</script>