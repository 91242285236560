<template>
  <div
    :id="item._id"
    class="container album"
    @click="click"
    :title="item.title"
    v-if="type == 'default'"
  >
    <img class="albumCover shadow" :src="cover" />
    <p class="albumTitle componentTitle">{{ item.title }}</p>
  </div>
  <div v-else-if="type == 'line'" class="album-line flex-row" @click="click">
    <img class="albumCover line shadow" :src="cover" />
    <p class="albumTitle line">
      <b>{{ item.title }}</b
      ><br />by<br /><b>{{ item.artist_name }}</b>
    </p>
  </div>
</template>
<script>
import BaseCollection from "../mixins/BaseCollection";

export default {
  name: "AlbumItem",
  mixins: [BaseCollection],
  props: {
    type: {
      type: String,
      default: "default",
    },
  },
  mounted() {
    if (this.$route.query.id == this.item._id && this.item.tracks) {
      this.$nextTick(() => {
        this.scrollFunction();
        this.$store.dispatch("albums/selectAlbum", this.item);
      });
    }
  },
  methods: {
    click() {
      if (this.$route.path != "/albums" && this.$route.path != "/artists") {
        this.$store.dispatch("albums/loadAlbum", this.item._id);
        this.$router.push("/albums?id=" + this.item._id);
      } else {
        this.scrollFunction();
      }
    },
  },
  computed: {
    cover() {
      let res = "/static/icons/dummy/album.svg";
      if (
        this.type == "default" &&
        this.item.covers &&
        this.item.covers.cover128
      ) {
        res = this.item.covers.cover128;
      } else if (
        this.type == "line" &&
        this.item.covers &&
        this.item.covers.cover64
      ) {
        res = this.item.covers.cover64;
      }
      return res;
    },
  },
};
</script>
<style scoped>
.albumTitle.line {
  max-width: initial;
  align-self: center;
  margin-left: 8px;
  line-height: 1.4;
}
</style>