<template>
  <div class="flex-column" style="max-height: 100%; height: 100%">
    <MessageScreen
      title="Still no Video content"
      subtitle="You still don't have any Video content on your instance"
      icon="video"
      :commands="messageCommands"
      @commandClicked="sync"
      :showCommands="$store.getters['user/isAdministrator']"
      v-if="boxes.length == 0 && !loading"
    />
    <BoxViewer />
    <div ref="boxes" id="boxes" @scroll="loadNextPage" @resize="loadNextPage">
      <BoxItem
        class="ma"
        v-for="box in boxes"
        :key="box._id"
        :item="box"
        @click="openBox(box)"
      />
      <div
        id="boxesLoadingControl"
        class="loadingItem"
        @click="loadNextPage"
        v-if="!eos"
      >
        Loading next Boxes...
      </div>
    </div>
  </div>
</template>
<script>
import BoxViewer from "../components/dialogs/BoxViewer";

import { mapGetters } from "vuex";

export default {
  name: "BoxesView",
  data() {
    return {
      scrollPosition: 0,
      messageCommands: [
        {
          title: "Scan for Video files",
          subtitle: "Scann your server for video files…",
          icon: "sync",
          command: "scan",
        },
      ],
      viewMenu: [
        {
          title: "Synchronize Videos",
          icon: "sync-alt",
          roles: ["admin"],
          event: this.sync,
        },
      ],
    };
  },
  mounted() {
    if (this.$route.query.id) {
      this.$store.dispatch("boxes/loadBox", this.$route.query.id);
    } else {
      this.loadNextPage(true);
    }
    this.$store.dispatch("setViewMenu", this.viewMenu);
  },
  computed: {
    ...mapGetters({
      boxes: ["boxes/collection"],
      loading: ["boxes/loading"],
      eos: ["boxes/eos"],
    }),
  },
  methods: {
    openBox(box) {
      this.$store.dispatch("boxes/selectBox", box);
    },
    loadNextPage(force) {
      this.scrollPosition = this.$refs.boxes.scrollTop;
      if ((!this.eos && this.isLoadingControlVisible()) || force === true) {
        this.$store.dispatch("boxes/loadBoxes", force);
      }
    },
    isLoadingControlVisible() {
      let element = document.getElementById("boxesLoadingControl");
      if (!element) {
        return false;
      }
      let bounding = element.getBoundingClientRect();

      let vVisible =
        bounding.top - 256 <=
          (window.innerHeight || document.documentElement.clientHeight) &&
        bounding.top > -256;
      return vVisible;
    },
    sync() {
      this.$store.dispatch("startScanningVideos");
      setTimeout(() => {
        this.loadNextPage(true);
      }, 3000);
    },
  },
  watch: {
    loading(newVal) {
      if (!newVal) {
        this.$nextTick(() => {
          this.loadNextPage();
          if (this.$store.getters["boxes/selectedBox"]._id) {
            this.$store.dispatch("boxes/preload");
          }
        });
      }
    },
    "$route.path": function (newVal) {
      if (newVal == "/boxes") {
        this.$nextTick(() => {
          this.$refs.boxes.scrollTop = this.scrollPosition;
          this.$store.dispatch("setViewMenu", this.viewMenu);
          if (!this.loading) {
            this.loadNextPage();
          }
        });
      }
    },
    "$route.query": function (newVal) {
      if (this.$route.path == "/boxes") {
        if (newVal.id) {
          if (this.$store.getters["boxes/selectedBox"]._id != newVal.id) {
            this.$store.dispatch("boxes/selectBoxById", newVal.id);
          }
        } else {
          this.$store.commit("boxes/resetSelectedBox");
        }
        if (!newVal.play) {
          this.$store.dispatch("videos/resetSelectedVideo");
        }
      } else {
        this.$store.commit("boxes/resetSelectedBox");
      }
    },
  },
  components: {
    BoxViewer,
  },
};
</script>
