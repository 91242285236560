import axios from 'axios'
import router from '../../../router'

export default {
  search(context, q) {
    axios.get(context.rootGetters.server + "/api/search/" + q, context.rootGetters.headers).then((res) => {
      context.commit("setResult", res.data);
    });
    router.push("/search?q=" + q);
  },
}