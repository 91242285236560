export default {
  resetViewMenu(state) {
    state.viewMenu = [];
  },
  searchFilter(state, term) {
    state.searchFilter = term;
  },
  setServer(state, server) {
    state.server = server;
  },
  setSystemSettings(state, payload) {
    state.serverConfig.allows = payload;
  },
  setViewMenu(state, menuItems) {
    state.viewMenu = menuItems || [];
  },
  setServerConfig(state, config) {
    state.serverConfig = config;
  },
  setServerConfigAllows(state, allows) {
    state.serverConfig.allows = allows;
  },
  setSystemConfigDomains(state, domains) {
    state.serverConfig.domains = domains;
  },
  setServerInfo(state, info) {
    state.serverInfo = info;
  },
  addPopUp(state, item) {
    item.time = Date.now();
    state.popups.unshift(item);
  },
  removePopUp(state, item) {
    let index = state.popups.indexOf(item);
    state.popups.splice(index, 1);
  }
}