<template>
  <DialogBase ref="dialogWindow" title="Shared items" button-text="close">
    <div class="flex-column" id="sharedContent">
      <ul v-if="shares.length > 0">
        <li
          class="flex-row pa4-top pa4-bottom"
          v-for="share in shares"
          :key="share._id"
        >
          <div class="flex-row grow">
            <a
              :href="'/#/share?id=' + share._id"
              target="webplay_share"
              :title="'Open ' + share.type"
            >
              <img
                class="cover ma-horizontal shadow pointer"
                :src="cover(share)"
              />
            </a>
            <p class="grow ma-off">
              {{ share.title }}
            </p>
            <button
              class="flat danger faded"
              @click="shareDisable(share)"
              title="Disable Sharing"
            >
              <awesome-icon icon="trash-alt" />
            </button>
          </div>
        </li>
      </ul>
      <h3 v-else class="ma">No shares on your instance</h3>
    </div>
  </DialogBase>
</template>


<script>
import { mapGetters } from "vuex";

export default {
  methods: {
    open() {
      this.loadCollection();
      this.$store.state.systemDialog = true;
      this.$refs.dialogWindow.open();
    },
    closed() {
      this.$store.state.systemDialog = false;
    },
    cover(item) {
      switch (item.type) {
        case "album":
          return item.cover || "/static/icons/dummy/album.svg";
        case "box":
          return item.cover || "/static/icons/dummy/box.svg";
      }
    },
    loadCollection() {
      this.$store.dispatch("share/loadShares");
    },
    shareDisable(share) {
      switch (share.type) {
        case "album":
          this.$store
            .dispatch("albums/getAlbumById", share.object_id)
            .then((item) => {
              this.$store
                .dispatch(
                  "albums/shareDisable",
                  item || { _id: share.object_id }
                )
                .then(() => {
                  this.loadCollection();
                });
            });

          break;
        case "box":
          this.$store
            .dispatch("boxes/getBoxById", share.object_id)
            .then((item) => {
              this.$store
                .dispatch(
                  "boxes/shareDisable",
                  item || { _id: share.object_id }
                )
                .then(() => {
                  this.loadCollection();
                });
            });
          break;
      }
    },
  },
  computed: {
    ...mapGetters({
      shares: ["share/collection"],
    }),
  },
};
</script>

<style scoped>
li p {
  align-self: center;
}

li .cover {
  width: 32px;
}

#sharedContent {
  min-width: 320px;
}
</style>