export default {
  clear(state) {
    state.collection = [];
    state.loading = false;
    state.eos = false;
    state.page = 1;
  },
  remove(state, id) {
    let artist = state.collection.find(f => f._id == id);
    if (artist) {
      let i = state.collection.indexOf(artist);
      state.collection.splice(i, 1);
    }
  },
  resetSelectedArtist(state) {
    if (state.selectedArtist._id)
      state.selectedArtist = { albums: [], tracks: [], covers: {} };
  },
  setArtists(state, artists) {
    if (artists.length == 0) {
      state.eos = true;
      state.loading = false;
      if (state.page > 1) {
        state.page--;
      }
      return;
    }
    artists.forEach(artist => {
      let existsArtist = state.collection.find(f => f._id == artist._id);
      if (!existsArtist) {
        let item = state.collection.find((item) => {
          if (item.name > artist.name) {
            return item;
          }
        })

        if (!artist.covers) {
          artist.covers = {};
        }
        if (item) {
          let index = state.collection.indexOf(item);
          state.collection.splice(index, 0, artist);
        } else {
          state.collection.push(artist);
        }

        artist.albums.forEach((album) => {
          album.parent = artist;
          if (!album.covers) {
            album.covers = {};
          }
          album.tracks.forEach((track) => {
            track.parent = album;
            track.parentType = "artist"
            artist.tracks.push(track);
          });
        });

      } else if (existsArtist && artist.albums.length > 0) {
        existsArtist.tracks = [];
        existsArtist.covers = artist.covers || {};
        existsArtist.albums = artist.albums;
        existsArtist.albums.forEach((album) => {
          album.parent = existsArtist;
          album.tracks.forEach((track) => {
            track.parent = album;
            track.parentType = "artist"
            existsArtist.tracks.push(track);
          });
        });
      }
    });
    state.loading = false;
  },
  selectArtist(state, artist) {
    if (state.selectedArtist._id != artist._id) {
      state.selectedArtist = artist;
    }
  },
}