<template>
  <div class="flex-column" style="max-height: 100%; height: 100%">
    <MessageScreen
      title="Still no Audio content"
      subtitle="You still don't have any Audio content on your instance"
      icon="music"
      :commands="messageCommands"
      @commandClicked="sync"
      :showCommands="$store.getters['user/isAdministrator']"
      v-if="albums.length == 0 && !loading"
    />
    <AlbumViewer />
    <div ref="albums" id="albums" @scroll="loadNextPage" @resize="loadNextPage">
      <AlbumItem
        class="ma"
        v-for="album in albums"
        :key="album._id"
        :item="album"
        @click="openAlbum(album)"
      />
      <div id="albumsLoadingControl" class="loadingItem" v-if="!eos">
        Loading next albums...
      </div>
    </div>
  </div>
</template>
<script>
import AlbumViewer from "../components/dialogs/AlbumViewer";

import { mapGetters } from "vuex";
export default {
  name: "AlbumsView",
  data() {
    return {
      scrollPosition: 0,
      messageCommands: [
        {
          title: "Scan for Audio files",
          subtitle: "Scann your server for audio files…",
          icon: "sync",
          command: "scan",
        },
      ],
      viewMenu: [
        {
          title: "Synchronize Music",
          icon: "sync-alt",
          roles: ["admin"],
          event: this.sync,
        },
      ],
    };
  },
  mounted() {
    if (this.$route.query.id) {
      this.$store.dispatch("albums/loadAlbum", this.$route.query.id);
    } else {
      this.loadNextPage(true);
    }
    this.$store.dispatch("setViewMenu", this.viewMenu);
  },
  computed: {
    ...mapGetters({
      albums: ["albums/collection"],
      loading: ["albums/loading"],
      eos: ["albums/eos"],
    }),
  },
  methods: {
    openAlbum(album) {
      this.$store.dispatch("albums/selectAlbum", album);
    },
    loadNextPage(force) {
      this.scrollPosition = this.$refs.albums.scrollTop;
      if ((!this.eos && this.isLoadingControlVisible()) || force === true) {
        this.$store.dispatch("albums/loadAlbums", force);
      }
    },
    isLoadingControlVisible() {
      let element = document.getElementById("albumsLoadingControl");
      if (!element) {
        return false;
      }
      let bounding = element.getBoundingClientRect();

      let vVisible =
        bounding.top - 256 <=
          (window.innerHeight || document.documentElement.clientHeight) &&
        bounding.top > -256;
      return vVisible;
    },
    sync() {
      this.$store.dispatch("startScanningMusic");
      setTimeout(() => {
        this.loadNextPage(true);
      }, 3000);
    },
  },
  watch: {
    loading(newVal) {
      if (!newVal) {
        this.$nextTick(() => {
          this.loadNextPage();
          if (this.$store.getters["albums/selectedAlbum"]._id) {
            this.$store.dispatch("albums/preloads");
          }
        });
      }
    },
    "$route.path": function (newVal) {
      if (newVal == "/albums") {
        this.$nextTick(() => {
          this.$refs.albums.scrollTop = this.scrollPosition;
          this.$store.dispatch("setViewMenu", this.viewMenu);
          if (!this.loading) {
            this.loadNextPage();
          }
        });
      }
    },
    "$route.query": function (newVal) {
      if (this.$route.path == "/albums") {
        if (newVal.id) {
          if (this.$store.getters["albums/selectedAlbum"]._id != newVal.id) {
            this.$store.dispatch("albums/selectAlbumById", newVal.id);
          }
        } else {
          this.$store.commit("albums/resetSelectedAlbum");
        }
      } else {
        this.$store.commit("albums/resetSelectedAlbum");
      }
    },
  },
  components: {
    AlbumViewer,
  },
};
</script>