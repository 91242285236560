<template>
  <div id="message-screen">
    <div class="ma-left ma-right">
      <div id="message-screen-header">
        <img v-if="img" :src="img" />
        <awesome-icon v-if="icon" :icon="icon" />
        <div>
          <h1>{{ title }}</h1>
          <h2>{{ subtitle }}</h2>
        </div>
      </div>
      <div id="message-screen-body" v-if="showCommands">
        <div
          class="message-screen-command"
          :class="{ 'margin-image': img || icon }"
          v-for="(command, index) in commands"
          :key="'Command-' + index"
          @click="$emit('commandClicked', command)"
        >
          <awesome-icon v-if="command.icon" size="2x" :icon="command.icon" />
          <img v-if="command.img" :src="command.img" />
          <div>
            <h3>{{ command.title }}</h3>
            <span>{{ command.subtitle }}</span>
          </div>
        </div>
        <ul
          class="message-screen-list"
          :class="{ 'margin-image': img || icon }"
        >
          <li
            v-for="(item, index) in list"
            :key="'ListItem-' + index"
            @click="$emit('listClicked', item)"
          >
            {{ listProperty != "" ? item[listProperty] : item }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    commands: {
      type: Array,
    },
    icon: {
      type: String,
      default: "",
    },
    img: {
      type: String,
      default: "",
    },
    list: {
      type: Array,
    },
    listProperty: {
      type: String,
      default: "",
    },
    subtitle: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    showCommands: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
