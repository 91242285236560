import axios from 'axios'
import router from '../../../router'

export default {
  clear(context) {
    context.commit("clear");
  },
  filter(context, term) {
    return new Promise((resolve) => {
      axios.get(context.rootGetters.server + "/api/albums/filter/" + term, context.rootGetters.headers).then(res => {
        resolve(res.data);
      });
    })
  },
  getNextTo(context, album) {
    return new Promise(resolve => {
      let i = context.getters.collection.indexOf(album);
      if (i > -1 && i < context.getters.collection.length - 1) {
        resolve(context.getters.collection[++i]);
      }
      resolve();
    });
  },
  getPrevTo(context, album) {
    return new Promise(resolve => {
      let i = context.getters.collection.indexOf(album);
      if (i > 0) {
        resolve(context.getters.collection[--i]);
      }
      resolve();
    })

  },
  loadAlbums(context, force) {
    return new Promise((resolve) => {
      if ((!context.state.eos || force) && !context.state.loading) {
        context.state.loading = true;
        axios.get(context.rootGetters.server + "/api/albums/page/" + context.state.page++, context.rootGetters.headers).then((res) => {
          context.commit("setAlbums", res.data);
          resolve(res.data);
        });
      }
    });
  },
  loadFavourites(context) {
    axios.get(context.rootGetters.server + "/api/albums/favourites", context.rootGetters.headers).then(res => {
      if (res.data.length > 0) {
        context.commit("setAlbums", res.data);
      }
    });
  },
  loadNewest(context) {
    axios.get(context.rootGetters.server + "/api/albums/newest/6", context.rootGetters.headers).then((res) => {
      context.commit("setNewest", res.data);
    });
  },
  loadAlbum(context, id) {
    context.state.loading = true;
    return new Promise((resolve) => {
      axios.get(context.rootGetters.server + "/api/albums/" + id, context.rootGetters.headers).then((res) => {
        if (res.data != "") {
          context.commit("setAlbums", [res.data]);
        } else {
          context.state.loading = false;
        }
        resolve(res.data);
      })
    })
  },
  resetSelectedAlbum(context) {
    context.commit("resetSelectedAlbum");
    router.push("/albums");
  },
  move(context, payload) {
    return new Promise((resolve) => {
      axios.put(context.rootGetters.server + "/api/albums/" + payload.source + "/move", payload, context.rootGetters.headers).then(res => {
        resolve(res.data);
      });
    })
  },
  remove(context, id) {
    context.commit("remove", id);
  },
  selectAlbum(context, album) {
    if (album.tracks.length == 0) {
      context.dispatch("loadAlbum", album._id);
    }

    context.commit('selectAlbum', album);
    if (context.rootGetters.routerQuery.id != album._id) {
      let url = "/albums?id=" + album._id;
      let track = context.rootGetters["tracks/selectedTrack"];
      if (track._id && track.parentType == "album" && track.parent._id == album._id) {
        url += "&play=" + track._id
      }
      router.push(url);
    }
    context.dispatch("preloads");
  },
  preloads(context) {
    // PRELOAD NEXT AND PREV ALBUM
    let next = context.getters.nextAlbum;
    if (next._id && next.tracks.length == 0) {
      context.dispatch("loadAlbum", next._id);
    }
    let prev = context.getters.prevAlbum;
    if (prev._id && prev.tracks.length == 0) {
      context.dispatch("loadAlbum", prev._id);
    }
  },
  selectAlbumById(context, id) {
    let album = context.getters.collection.find(item => item._id == id);
    if (album) {
      context.dispatch("selectAlbum", album);
    }
  },
  getAlbumById(context, id) {
    return new Promise(resolve => {
      let album = context.getters.collection.find(item => item._id == id);
      resolve(album);
    });
  },
  gotoPrevAlbum(context) {
    let prevAlbum = context.getters.prevAlbum;
    if (prevAlbum._id) {
      context.dispatch("selectAlbum", prevAlbum);
    }
  },
  gotoNextAlbum(context) {
    let nextAlbum = context.getters.nextAlbum;
    if (nextAlbum._id) {
      context.dispatch("selectAlbum", nextAlbum);
    }
  },
  uploadNewCover(context, album) {
    let input = document.createElement('input');
    input.type = "file";
    input.accept = "image/jpeg, image/png";
    input.addEventListener("change", function () {
      if (input.value) {
        let formData = new FormData();
        formData.append("file", input.files[0]);
        let h = context.rootGetters.headers;
        h.headers["content-type"] = "multipart/form-data";
        axios
          .put(context.rootGetters.server + "/api/albums/" + album._id + "/cover", formData, context.rootGetters.headers)
          .then(res => {
            album.covers = res.data;
          });
      }
    });
    input.click();
  },
  resetCover(context, album) {
    axios.delete(context.rootGetters.server + "/api/albums/" + album._id + "/cover", context.rootGetters.headers).then(() => {
      album.covers = {}
    });
  },
  updateAlbum(context, album) {
    let body = {
      _id: album._id,
      visibility: album.visibility
    }
    axios.put(context.rootGetters.server + "/api/albums/" + album._id, body, context.rootGetters.headers);
  },
  shareEnable(context, album) {
    return new Promise(resolve => {
      axios.post(context.rootGetters.server + "/api/albums/" + album._id + "/share", {}, context.rootGetters.headers).then(res => {
        album.share = res.data;
        resolve();
        context.dispatch("addPoUp", { title: "Share successful", message: "Url was copied into your clipboard", type: "success", icon: "share" }, { root: true });
      });
    });
  },
  shareDisable(context, album) {
    return new Promise(resolve => {
      axios.delete(context.rootGetters.server + "/api/albums/" + album._id + "/share", context.rootGetters.headers).then(() => {
        album.share = {};
        resolve();
      });
    });
  },
  loadRandomCovers(context, count) {
    axios.get(context.rootGetters.server + "/api/albums/random/" + count, context.rootGetters.headers).then((res) => {
      context.commit("setRandomCovers", res.data);
    });
  }
}