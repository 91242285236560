export default {
    clear(state) {
        state.collection = [];
        state.loading = false;
        state.eos = false;
        state.page = 1;
    },
    resetSelectedRadio(state) {
        if (!state.selectedRadio._id) {
            return;
        }
        state.selectedRadio = {};
    },
    selectRadio(state, radio) {
        if (state.selectedRadio._id != radio._id) {
            state.selectedRadio = radio;
        }
    },
    setRadios(state, radios) {
        state.collection = radios;
    },
}