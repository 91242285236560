<template>
  <DialogBase ref="dialogWindow" :title="album_title" @canceled="closed" :showFooter="false" :disableXscroll="true" :disableYscroll="true" :flatDialogHeader="true">
    <div id="albumViewer" class="flex-row">
      <div id="header" class="flex-column grow">
        <div id="background" :style="coverBackground" />
        <div id="title" class="flex-column pa grow z1">
          <img class="glow ma" :src="cover" />
          <span id="stats" class="center">
            {{ selectedAlbum.title }}
            <br />
            by
            <b @click="gotoArtist" class="pointer">{{ selectedAlbum.artist_name }}</b>
            <br />
            <span v-if="album_year">
              from year <b>{{ album_year }}</b> </span><br />
            <b>{{ album_tracks.length }}</b> Tracks with a duration of
            <b>{{ album_duration }}</b>
          </span>
          <div class="grow"> </div>
          <div class="flex-row grow ma-top">
            <button class="flat ma-right" title="Favourite" @click="toggleFavourite">
              <awesome-icon :icon="['fas', 'star']" size="2x" class="white-text favourite active" v-if="isFavourite" />
              <awesome-icon :icon="['far', 'star']" size="2x" class="white-text favourite" v-else />
            </button>
            <button class="flat ma-right" title="Keep playing" v-if="selectedAlbum._id == selectedTrackContainer._id && isPlaying">
              <awesome-icon icon="compact-disc" size="2x" class="primary-text" />
            </button>
            <button class="flat ma-right" title="Keep playing" @click="playProgress" v-else-if="selectedAlbum.progress">
              <awesome-icon icon="play" size="2x" class="primary-text" />
            </button>
            <button class="flat ma-right" title="Play" @click="playAlbum" v-else>
              <awesome-icon icon="play" size="2x" class="white-text" />
            </button>
            <button @click="gotoPrevAlbum" class="flat ma-left ma-right" :title="prevAlbum.name" :disabled="!prevAlbum._id">
              <awesome-icon icon="angle-left" class="ma4 white-text" />
            </button>
            <button @click="gotoNextAlbum" class="flat" :title="nextAlbum.name" :disabled="!nextAlbum._id">
              <awesome-icon icon="angle-right" class="ma4 white-text" />
            </button>
            <div class="grow"></div>
            <DropDown v-if="$store.getters['user/isAdministrator']">
              <button class="flat center" :title="visibility_text">
                <awesome-icon :icon="visibility_icon" class="white-text" />
              </button>
              <template v-slot:dropdown-content>
                <div>
                  <button v-for="(item, i) in $store.state.system.lists.visibility" :key="i" @click="setVisibility(item)">
                    <awesome-icon :icon="getVisibilityIcon(item)" />{{
                      getVisibilityText(item)
                    }}
                  </button>
                  <hr />
                  <button v-if="!selectedAlbum.share._id" @click="shareEnable">
                    <awesome-icon icon="share" />Share this album
                  </button>
                  <button v-if="selectedAlbum.share._id" @click="addShareUrlToClipboard">
                    <awesome-icon icon="clipboard" />Copy url into clipboard
                  </button>
                  <button v-if="selectedAlbum.share._id" @click="shareDisable">
                    <awesome-icon icon="share" />Remove share
                  </button>
                </div>
              </template>
            </DropDown>
            <DropDown v-if="$store.getters['user/isAdministrator']">
              <button class="flat center">
                <awesome-icon icon="ellipsis-v" class="white-text" />
              </button>
              <template v-slot:dropdown-content>
                <div>
                  <button @click="uploadNewCover">
                    <awesome-icon icon="image" />Set new Cover...
                  </button>
                  <button @click="resetCover">
                    <awesome-icon icon="eraser" />Reset Cover
                  </button>
                  <hr />
                  <button @click="mergeAlbum">
                    <awesome-icon icon="compress-alt" />Merge Albums...
                  </button>
                </div>
              </template>
            </DropDown>
          </div>
        </div>
      </div>
      <ul id="trackList" class="tracks">
        <li v-for="track in selectedAlbum.tracks" :key="track._id">
          <TrackItem :track="track" :showCover="false" />
        </li>
      </ul>
    </div>
    <AlbumMerge ref="mergeDialog" />
  </DialogBase>
</template>

<script>
import AlbumMerge from "./AlbumMerge.vue";
import TrackItem from "../Track";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      albums: [],
      scrollTimer: 0,
      loadingPrev: false,
      loadingNext: false,
      elements: {},
    };
  },
  methods: {
    addShareUrlToClipboard() {
      let url =
        window.location.origin + "/#/share?id=" + this.selectedAlbum.share._id;
      navigator.clipboard.writeText(url);
    },
    playAlbum() {
      this.$store.commit("tracks/resetSelectedTrack");
      this.$store.commit("radios/resetSelectedRadio");
      this.$store.dispatch("tracks/playContainer", this.selectedAlbum);
    },
    gotoArtist() {
      let artist = this.$store.getters["artists/collection"].find(
        (f) => f._id == this.selectedAlbum.artist_id
      );
      if (artist) {
        this.$store.dispatch("artists/selectArtist", artist);
      } else {
        this.$store
          .dispatch("artists/loadArtist", this.selectedAlbum.artist_id)
          .then((artist) => {
            this.$store.dispatch("artists/selectArtist", artist);
          });
      }
    },
    gotoNextAlbum() {
      this.$store.dispatch("albums/gotoNextAlbum");
    },
    gotoPrevAlbum() {
      this.$store.dispatch("albums/gotoPrevAlbum");
    },
    gotoTrack() {
      if (this.$route.query.play) {
        let track = this.selectedAlbum.tracks.find(
          (f) => f._id == this.$route.query.play
        );
        if (track) {
          this.$store.dispatch("tracks/play", track);
        }
      }
    },
    playProgress() {
      let track = this.selectedAlbum.tracks.find(
        (f) => f._id == this.selectedAlbum.progress.id
      );
      if (track) {
        this.$store.dispatch("tracks/play", track);
      }
    },
    closed() {
      if (
        (window.history.state.back &&
          window.history.state.back.indexOf("?") == -1) ||
        window.history.state.back.startsWith("/search")
      ) {
        this.$router.back();
      } else {
        this.$store.dispatch("albums/resetSelectedAlbum");
      }
      this.albums = [];
    },
    keydownListener(e) {
      if (e.key == "ArrowLeft") {
        e.preventDefault();
        this.gotoPrevAlbum();
      }
      if (e.key == "ArrowRight") {
        e.preventDefault();
        this.gotoNextAlbum();
      }
    },
    mergeAlbum() {
      this.$refs.mergeDialog.open(this.selectedAlbum);
    },
    setVisibility(visibility) {
      this.selectedAlbum.visibility = visibility;
      this.$store.dispatch("albums/updateAlbum", this.selectedAlbum);
    },
    toggleFavourite() {
      this.$store.dispatch("user/toggleFavourite", {
        itemId: this.selectedAlbum._id,
        type: "album",
      });
    },
    uploadNewCover() {
      this.$store.dispatch("albums/uploadNewCover", this.selectedAlbum);
    },
    resetCover() {
      this.$store.dispatch("albums/resetCover", this.selectedAlbum);
    },
    getVisibilityIcon(visibility) {
      return visibility == "global"
        ? "globe"
        : visibility == "instance"
          ? "server"
          : visibility == "hidden"
            ? "eye-slash"
            : "user";
    },
    getVisibilityText(visibility) {
      return visibility == "global"
        ? "Global"
        : visibility == "instance"
          ? "On this server"
          : visibility == "hidden"
            ? "Hide this Album"
            : "Only for me";
    },
    selectAlbum(album) {
      this.$store.dispatch("albums/selectAlbum", album);
    },
    shareEnable() {
      this.$store
        .dispatch("albums/shareEnable", this.selectedAlbum)
        .then(() => {
          this.addShareUrlToClipboard();
        });
    },
    shareDisable() {
      this.$store.dispatch("albums/shareDisable", this.selectedAlbum);
    },
    loadUserProgress() {
      if (this.selectedTrack.parent._id != this.selectedAlbum._id) {
        this.$store
          .dispatch("user/getProgress", this.selectedAlbum)
          .then(() => {
            this.gotoTrack();
          });
      }
    }
  },
  computed: {
    ...mapGetters({
      prevAlbum: ["albums/prevAlbum"],
      nextAlbum: ["albums/nextAlbum"],
      selectedAlbum: ["albums/selectedAlbum"],
      selectedTrack: ["tracks/selectedTrack"],
      selectedTrackContainer: ["tracks/selectedTrackContainer"],
      favourites: ["user/favourites"],
      isPlaying: ["player/isPlaying"],
    }),
    album_title() {
      return this.selectedAlbum.title;
    },
    album_year() {
      return this.selectedAlbum.year;
    },
    album_tracks() {
      return this.selectedAlbum.tracks || [];
    },
    album_duration() {
      if (!this.selectedAlbum.tracks) {
        return 0;
      }
      let duration = 0;
      let hours = 0;
      let minutes = 0;
      let seconds = 0;

      this.selectedAlbum.tracks.forEach((track) => {
        duration += track.duration;
      });

      if (duration >= 3600) {
        hours = parseInt(duration / 3600);
        duration -= hours * 3600;
      }

      minutes = parseInt(duration / 60);
      seconds = parseInt(duration - minutes * 60);
      return (
        (hours > 0 ? hours + ":" : "") +
        (minutes < 10 ? "0" : "") +
        minutes +
        ":" +
        (seconds < 10 ? "0" : "") +
        seconds
      );
    },
    coverBackground() {
      return "background-image: url('" + this.cover + "')";
    },
    cover() {
      return (
        this.selectedAlbum.covers.cover256 || "/static/icons/dummy/album.svg"
      );
    },
    visibility_icon() {
      return this.selectedAlbum.visibility == "global"
        ? "globe"
        : this.selectedAlbum.visibility == "instance"
          ? "server"
          : this.selectedAlbum.visibility == "hidden"
            ? "eye-slash"
            : "user";
    },
    visibility_text() {
      return this.selectedAlbum.visibility == "global"
        ? "Visible for the whole world"
        : this.selectedAlbum.visibility == "instance"
          ? "Visible on this instance"
          : this.selectedAlbum.visibility == "hidden"
            ? "Hidden for all users"
            : "Visible only for me";
    },
    isFavourite() {
      return (
        this.favourites.find((f) => f.itemId == this.selectedAlbum._id) !=
        undefined
      );
    },
  },
  watch: {
    selectedAlbum(newVal) {
      if (newVal._id) {
        if (this.albums.length == 0) {
          this.albums.push(newVal);
        }
        if (!this.$refs.dialogWindow.visible) {
          this.$refs.dialogWindow.open();
          window.addEventListener("keydown", this.keydownListener);
        }
        this.loadUserProgress();
      } else {
        if (this.$refs.dialogWindow.visible) {
          this.$refs.dialogWindow.close();
        }
        window.removeEventListener("keydown", this.keydownListener);
      }
    },
  },
  components: {
    AlbumMerge,
    TrackItem,
  },
};
</script>

<style scoped>
#albumViewer {
  height: 400px;
  width: 640px;
}

#header {
  width: 304px;
  height: 400px;
  position: relative;
  background-color: black;
}

#header img {
  align-self: center;
  width: 256px;
}

#stats {
  z-index: 2;
  color: var(--white);
  text-shadow: 0 1px 2px black;
}

.dropdown-activator button {
  width: 32px;
  height: 32px;
}

#trackList {
  background-color: var(--white);
  z-index: 1;
}

@media (max-width: 480px) {
  #header {
    width: 100%;
  }

  #albumViewer {
    flex-direction: column;
  }
}

@media (max-width: 480px),
(max-height: 480px) {
  #albumViewer {
    width: 100%;
    height: 100%;
  }

  #trackList {
    height: initial;
    flex-grow: 1;
  }
}
</style>