import axios from 'axios'
import router from '../../../router'

export default {
  clear(context) {
    context.commit("clear");
  },
  filter(context, term) {
    return new Promise((resolve) => {
      axios.get(context.rootGetters.server + "/api/boxes/filter/" + term, context.rootGetters.headers).then(res => {
        resolve(res.data);
      });
    })
  },
  loadBoxes(context, force) {
    return new Promise((resolve) => {
      if ((!context.state.eos || force) && !context.state.loading) {
        context.state.loading = true;
        axios.get(context.rootGetters.server + "/api/boxes/page/" + context.state.page++, context.rootGetters.headers).then((res) => {
          context.commit("setBoxes", res.data);
          resolve(res.data);
        });
      }
    });
  },
  loadFavourites(context) {
    axios.get(context.rootGetters.server + "/api/boxes/favourites", context.rootGetters.headers).then(res => {
      if (res.data.length > 0) {
        context.commit("setBoxes", res.data);
      }
    });
  },
  loadNewest(context) {
    axios.get(context.rootGetters.server + "/api/boxes/newest/6", context.rootGetters.headers).then((res) => {
      context.commit("setNewest", res.data);
    });
  },
  loadBox(context, id) {
    context.state.loading = true;
    axios.get(context.rootGetters.server + "/api/boxes/" + id, context.rootGetters.headers).then((res) => {
      if (res.data != "") {
        context.commit("setBoxes", [res.data]);
      } else {
        context.state.loading = false;
      }
    });
  },
  resetSelectedBox(context) {
    context.commit("resetSelectedBox");
    router.push("/boxes");
  },
  selectBox(context, box) {
    if (box.videos.length == 0) {
      context.dispatch("loadBox", box._id)
    }

    context.commit('selectBox', box);
    if (!context.rootGetters.routerQuery || context.rootGetters.routerQuery.id != box._id) {
      router.push("/boxes?id=" + box._id);
    }

    context.dispatch("preload")
  },
  preload(context) {
    // PRELOAD NEXT AND PREV ALBUM
    let next = context.getters.nextBox;
    if (next._id && next.videos.length == 0) {
      context.dispatch("loadBox", next._id);
    }
    let prev = context.getters.prevBox;
    if (prev._id && prev.videos.length == 0) {
      context.dispatch("loadBox", prev._id);
    }
  },
  selectBoxById(context, id) {
    let box = context.getters.collection.find(item => item._id == id);
    if (box) {
      context.dispatch("selectBox", box);
    }
  },
  getBoxById(context, id) {
    return new Promise(resolve => {
      let box = context.getters.collection.find(item => item._id == id);
      resolve(box);
    });
  },
  gotoPrevBox(context) {
    let prevBox = context.getters.prevBox;
    if (prevBox._id) {
      context.dispatch("selectBox", prevBox);
    }
  },
  move(context, payload) {
    return new Promise((resolve) => {
      axios
        .put(context.rootGetters.server + "/api/boxes/" + payload.source + "/move", payload, context.rootGetters.headers)
        .then(res => {
          resolve(res.data);
        });
    })
  },
  remove(context, id) {
    context.commit("remove", id);
  },
  gotoNextBox(context) {
    let nextBox = context.getters.nextBox;
    if (nextBox._id) {
      context.dispatch("selectBox", nextBox);
    }
  },
  updateBox(context, box) {
    let body = {
      _id: box._id,
      visibility: box.visibility
    }
    axios.put(context.rootGetters.server + "/api/boxes/" + box._id, body, context.rootGetters.headers);
  },
  uploadNewCover(context, box) {
    let input = document.createElement('input');
    input.type = "file";
    input.accept = "image/jpeg, image/png";
    input.addEventListener("change", function () {
      if (input.value) {
        let formData = new FormData();
        formData.append("file", input.files[0]);
        let h = context.rootGetters.headers;
        h.headers["content-type"] = "multipart/form-data";
        axios
          .put(context.rootGetters.server + "/api/boxes/" + box._id + "/cover", formData, context.rootGetters.headers)
          .then(res => {
            box.covers = res.data;
          });
      }
    });
    input.click();
  },
  resetCover(context, box) {
    axios.delete(context.rootGetters.server + "/api/boxes/" + box._id + "/cover", context.rootGetters.headers).then(() => {
      box.covers = {}
    });
  },
  shareEnable(context, box) {
    return new Promise(resolve => {
      axios.post(context.rootGetters.server + "/api/boxes/" + box._id + "/share", {}, context.rootGetters.headers).then(res => {
        box.share = res.data;
        resolve();
        context.dispatch("addPoUp", { title: "Share successful", message: "Url was copied into your clipboard", type: "success", icon: "share" }, { root: true });
      });
    });
  },
  shareDisable(context, box) {
    return new Promise(resolve => {
      axios.delete(context.rootGetters.server + "/api/boxes/" + box._id + "/share", context.rootGetters.headers).then(() => {
        box.share = {};
        resolve();
      });
    });
  }
}