export default {
  clear(state) {
    state.collection = [];
    state.newest = [];
    state.loading = false;
    state.eos = false;
    state.page = 1;
  },
  remove(state, id) {
    let box = state.collection.find(f => f._id == id);
    if (box) {
      let i = state.collection.indexOf(box);
      state.collection.splice(i, 1);
    }
  },
  resetSelectedBox(state) {
    if (state.selectedBox._id)
      state.selectedBox = { videos: [], covers: {} };
  },
  setBoxes(state, boxes) {
    if (boxes.length == 0) {
      state.eos = true;
      state.loading = false;
      if (state.page > 1) {
        state.page--;
      }
      return;
    }
    boxes.forEach(box => {
      let existsBox = state.collection.find(f => f._id == box._id);
      if (!existsBox) {
        let item = state.collection.find((item) => {
          if (item.title > box.title) {
            return item;
          }
        })

        if (!box.covers) {
          box.covers = {}
        }

        if (item) {
          let index = state.collection.indexOf(box);
          state.collection.splice(index, 0, box);
        } else {
          state.collection.push(box);
        }
        box.videos.forEach((video) => {
          video.parent = box;
        });
      } else if (existsBox && box.videos.length > 0) {
        existsBox.covers = box.covers || {};
        existsBox.share = box.share;
        existsBox.videos = box.videos;
        existsBox.videos.forEach((video) => {
          video.parent = existsBox;
        });
      }
    });
    state.loading = false;
  },
  selectBox(state, box) {
    if (state.selectedBox._id != box._id) {
      state.selectedBox = box;
    }
  },
  setNewest(state, boxes) {
    boxes.forEach(box => {
      if (!box.covers) {
        box.covers = {};
      }
    });
    state.newest = boxes;
  }
}