<template>
  <DialogBase
    ref="dialogWindow"
    title="Users"
    :showCloseButton="false"
    buttonText="Close"
    @closed="closed"
    @opened="loadContent"
  >
    <template v-slot:header-right>
      <div id="newUser">
        <input
          placeholder="New User"
          v-model="newUser"
          ref="newUser"
          type="text"
          required
          pattern=".+"
          autocomplete="one-time-code"
        />
        <input
          placeholder="Password"
          v-model="newPass"
          ref="newPass"
          type="password"
          @keydown.enter="validateAndSaveInput"
          required
          pattern=".{5,}"
        />
        <button
          @click="validateAndSaveInput"
          title="Add new Useraccount"
          class="success"
        >
          <awesome-icon icon="user-plus" />
        </button>
      </div>
    </template>
    <div id="usersBody" class="ma-top ma-left ma-bottom">
      <table class="padding">
        <thead>
          <tr>
            <th>Name</th>
            <th v-if="$store.getters['user/isAdministrator']">Roles</th>
            <th>Last Access</th>
            <th>Change Password</th>
            <th class="slim" v-if="$store.getters['user/isAdministrator']"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(user, i) in users" :key="i">
            <td :class="{ me: user._id == me._id }">
              {{ user.name }}
            </td>
            <td v-if="$store.getters['user/isAdministrator']">
              <DropDown v-if="user._id != me._id" :closeOnClick="false">
                <button class="flat">
                  <awesome-icon icon="user-cog" />
                </button>
                <template v-slot:dropdown-content>
                  <div
                    class="user-role ma4"
                    v-for="(role, i) in lists.user_role"
                    :key="i"
                  >
                    <input
                      :id="role"
                      class="ma4-right"
                      type="checkbox"
                      :checked="user.roles && user.roles.includes(role)"
                      @change="changeRole(user, role)"
                    />
                    <label :for="role">{{ role }}</label>
                  </div>
                </template>
              </DropDown>
            </td>
            <td class="right">{{ user.last_access }}</td>
            <td>
              <input
                type="password"
                placeholder="New Password"
                @keydown.enter="updateUser(user, $event)"
              />
            </td>
            <td v-if="$store.getters['user/isAdministrator']" class="slim">
              <button
                @click="deleteUser(user)"
                title="Remove Useraccount"
                class="flat danger faded"
                v-if="user._id != me._id"
              >
                <awesome-icon icon="trash-alt" />
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </DialogBase>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "UsersDialog",
  data() {
    return {
      newUser: "",
      newPass: "",
    };
  },
  methods: {
    open() {
      this.$store.state.systemDialog = true;
      this.$refs.dialogWindow.open();
      this.$nextTick(() => {
        this.$refs.newUser.focus();
      });
    },
    closed() {
      this.$store.state.systemDialog = false;
    },
    loadContent() {
      this.$store.dispatch("system/loadUsers");
    },
    updateUser(user, event) {
      var newUserPassword = event.srcElement.value;
      if (newUserPassword.length == 0) {
        console.log("No Password");
        return;
      }
      user.newPassword = newUserPassword;
      this.$store.dispatch("system/updateUser", user).then(() => {
        this.$refs.dialogWindow.messageText =
          "Password changed for " + user.name;
        this.$refs.dialogWindow.focusButton();
      });
    },
    deleteUser(user) {
      this.$store.dispatch("system/deleteUser", user);
    },
    validateAndSaveInput() {
      this.newUser = this.newUser.trim();
      if (this.newUser.length == 0) {
        this.$refs.dialogWindow.messageText = "No Username";
        this.$refs.newUser.focus();
        return;
      }
      if (this.newPass.length == 0) {
        this.$refs.dialogWindow.messageText = "No Password";
        this.$refs.newPass.focus();
        return;
      }

      let newUser = { name: this.newUser, password: this.newPass };
      this.$store
        .dispatch("system/addUserIfNotExists", newUser)
        .then((success) => {
          if (!success) {
            this.$refs.dialogWindow.messageText = "Username bereits vergeben";
          }
        });
    },
    changeRole(user, role) {
      let i = user.roles.indexOf(role);
      if (i > -1) {
        user.roles.splice(i, 1);
      } else {
        user.roles.push(role);
      }
      delete user.newPassword;
      this.$store.dispatch("system/updateUser", user);
    },
  },
  computed: {
    ...mapGetters({
      users: ["system/users"],
      lists: ["system/lists"],
    }),
    me() {
      return this.$store.state.user;
    },
  },
};
</script>

<style scoped>
#newUser {
  display: flex;
}
#usersBody th {
  text-align: left;
}
#usersBody td {
  color: var(--gray);
}
#usersBody .me {
  font-weight: bold;
}
#usersBody .user-role {
  display: flex;
  align-items: center;
}
td input {
  width: 100%;
}
</style>