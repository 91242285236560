<template>
  <DialogBase ref="dialogWindow" @closing="closing" :showFooter="false" :showFullscreenButton="true">
    <template v-slot:header-right>
      <span v-if="selectedVideo.tracks && selectedVideo.tracks.length > 1">Language:</span>
      <select v-if="selectedVideo.tracks && selectedVideo.tracks.length > 1" @change="langChanged" v-model="selectedLang">
        <option v-for="(lang, i) in selectedVideo.tracks" :key="i" :value="lang" :title="lang.title">
          {{ lang.lang.toUpperCase() }}
        </option>
      </select>
    </template>
    <video @ended="nextVideo" @timeupdate="timeUpdate" @playing="playing" @pause="pause" controls style="height: 100%; width: 100%; background: black" ref="videoControl" src></video>
  </DialogBase>
</template>
<script>
export default {
  data() {
    return {
      video: undefined,
      languages: [],
      selectedLang: {},
      preConvert: false,
      intervalState: 0,
    };
  },
  methods: {
    langChanged() {
      this.video.src =
        this.$store.getters["videos/getStreamUrl"] + this.langIndex;
      this.video.play();
    },
    pause() {
      window.clearInterval(this.intervalState);
    },
    playing() {
      window.clearInterval(this.intervalState);
      this.pushState();

      this.intervalState = setInterval(() => {
        this.pushState();
      }, 10000);
    },
    pushState() {
      if (!this.currentUser._id || !this.video || !this.selectedVideo._id) {
        return;
      }
      let item = {
        id: this.selectedVideo._id,
        parentId: this.selectedVideo.parent._id,
        type: "video",
        progress: Math.round(this.video.currentTime)
      }
      this.$store.dispatch("user/saveProgress", item);
    },

    playVideo(video) {
      this.$store.commit("radios/resetSelectedRadio");
      this.$store.commit("tracks/resetSelectedTrack");
      this.$refs.dialogWindow.open(video.title);
      this.preConvert = false;
      this.languages = [];
      if (!this.video) {
        this.$nextTick(() => {
          this.video = this.$refs.videoControl;
          this.playStream(video);
        });
      } else {
        this.playStream(video);
      }
    },
    playStream(video) {
      let lastLang = this.selectedLang.lang;
      let findLang = video.tracks.find(
        (f) =>
          f.lang.toUpperCase() ==
          (
            lastLang ||
            this.$store.getters["user/settings"].video_lang ||
            "ENG"
          ).toUpperCase()
      );

      this.selectedLang = findLang || video.tracks[0];

      this.video.src =
        this.$store.getters["videos/getStreamUrl"] + this.langIndex;
      if (this.selectedVideo.parent.progress) {
        if (this.selectedVideo.parent.progress.id == this.selectedVideo._id) {
          this.skipToSecond(this.selectedVideo.parent.progress.progress);
        }
        this.selectedVideo.parent.progress = undefined;
      }

      this.video.play();

      this.pushHistoryItem();
    },
    nextVideo() {
      this.$store.dispatch("videos/playNextTo", this.selectedVideo);
    },
    pushHistoryItem() {
      if (!this.currentUser._id) {
        return;
      }
      let item = {
        id: this.selectedVideo.parent._id,
        type: "box",
        title: this.selectedVideo.parent.title,
        covers: { cover128: this.selectedVideo.parent.covers.cover128 },
      };
      this.$store.dispatch("user/saveHistoryItem", item);
      this.scaleImage(this.selectedVideo.thumbnail, 0.5, (img) => {
        item = {
          id: this.selectedVideo._id,
          type: "video",
          title: this.selectedVideo.title,
          thumbnail: img,
          parent: { _id: this.selectedVideo.parent._id },
        };
        this.$store.dispatch("user/saveHistoryItem", item);
      });
    },
    timeUpdate(event) {
      let percent = (event.target.currentTime / event.target.duration) * 100;
      if (percent > 30 && !this.preConvert) {
        this.preConvert = true;
        this.$store.dispatch("videos/convertNextTo", {
          video: this.selectedVideo,
          langIndex: this.langIndex,
        });
      }
    },
    reset() {
      if (this.$refs.dialogWindow.visible) {
        this.$refs.dialogWindow.close();
      }
    },
    skipToSecond(second) {
      let was_paused = this.video.paused;
      this.video.pause();
      this.video.currentTime = second;
      if (!was_paused) {
        this.video.play();
      }
    },
    closing() {
      window.clearInterval(this.intervalState);
      this.pushState();
      this.video = undefined;
      this.$store.dispatch("videos/resetSelectedVideo");
    },
    scaleImage(src, factor, callback) {
      let img = document.createElement("img");

      img.onload = () => {
        var canvas = document.createElement("canvas");
        var ctx = canvas.getContext("2d");
        ctx.imageSmoothingEnabled = true;
        ctx.imageSmoothingQuality = "high";

        canvas.width = img.width * factor;
        canvas.height = img.height * factor;

        let width = img.width;
        let height = img.height;

        ctx.drawImage(
          img,
          0,
          0,
          width,
          height,
          0,
          0,
          width * factor,
          height * factor
        );

        callback(canvas.toDataURL());
      };
      img.src = src;
    },
  },
  computed: {
    currentUser() {
      return this.$store.getters["user/user"];
    },
    selectedVideo() {
      return this.$store.getters["videos/selectedVideo"];
    },
    langIndex() {
      return this.selectedVideo.tracks.indexOf(this.selectedLang);
    },
  },
  watch: {
    selectedVideo(newVal) {
      if (newVal._id) {
        this.playVideo(newVal);
      } else {
        this.reset();
      }
    },
  },
};
</script>