import { createApp } from 'vue'

import App from './App.vue'

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import DialogBase from "./components/base-components/Dialog";
import DropDown from "./components/base-components/DropDown";
import MessageScreen from "./components/base-components/MessageScreen";

import AlbumItem from "./components/Album"
import ArtistItem from "./components/Artist"
import BoxItem from "./components/Box"

library.add(fas)
library.add(far)

import store from "./store/index";
import router from './router'

const app = createApp(App);
app.use(store);
app.use(router);
app.component('awesome-icon', FontAwesomeIcon);
app.component('AlbumItem', AlbumItem);
app.component('ArtistItem', ArtistItem);
app.component('BoxItem', BoxItem);
app.component('DialogBase', DialogBase);
app.component('DropDown', DropDown);
app.component('MessageScreen', MessageScreen);
app.mount('#app');
