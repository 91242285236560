<template>
  <div id="boxContent" class="flex-column">
    <div class="flex-column pa-horizontal border-bottom hideOnMobile">
      <h1>{{ box.title }}</h1>
    </div>
    <div id="box" class="flex-row">
      <div id="header" class="flex-column relative">
        <div id="background" :style="coverBackground" />
        <img id="cover" class="shadow ma24" :src="cover" />
        <p class="center ma-off hideOnMobile">
          <b>{{ box.videos.length }}</b> Videos
        </p>
      </div>
      <div id="videos" class="flex-column grow">
        <ul id="videoList" class="videos">
          <li v-for="video in box.videos" :key="video._id">
            <VideoItem :video="video" />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import VideoItem from "../components/Video.vue";
export default {
  data() {
    return {
      box: {
        covers: {},
        videos: [],
      },
    };
  },
  methods: {
    show(box) {
      this.box = box;
    },
  },
  computed: {
    cover() {
      if (!this.box.covers) {
        return "/static/icons/dummy/box.svg";
      }
      return this.box.covers.cover256;
    },
    coverBackground() {
      return "background-image: url('" + this.cover + "'); z-index: -1";
    },
  },
  components: {
    VideoItem,
  },
};
</script>

<style scoped>
#cover {
  align-self: center;
  width: 256px;
}

#boxContent {
  overflow: hidden;
  width: 100%;
}
#box {
  overflow: auto;
}

#header {
  overflow: hidden;
}

#videos {
  background-color: var(--white);
  overflow-y: auto;
}

.video {
  max-width: 256px;
}

@media (max-width: 480px) {
  #box {
    flex-direction: column;
    max-height: 100%;
  }
  #videos {
    border-top: 1px solid var(--light-border);
  }
  .video {
    max-width: inherit;
  }
}
</style>