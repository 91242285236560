<template>
  <div
    :id="track._id"
    :title="track.title"
    class="track"
    @click="clicked"
    :class="{ selected: track._id == selectedTrack._id }"
  >
    <div class="trackDetails">
      <img
        v-if="showCover == true"
        :src="cover"
        :title="track.parent.title"
        class="trackCover shadow"
      />
      <div class="trackTitle">{{ track.title }}</div>
      <div class="trackDuration" v-if="track.duration">{{ duration }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TrackItem",
  props: {
    track: {
      type: Object,
    },
    showCover: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {
    this.scrollFunction();
  },
  methods: {
    clicked() {
      if (this.$route.path == "/" || this.$route.path == "/search") {
        this.$store.dispatch("albums/loadAlbum", this.track.parent._id);
        this.$router.push(
          "/albums?id=" + this.track.parent._id + "&play=" + this.track._id
        );
      } else {
        this.$store.dispatch("tracks/play", this.track);
      }
    },
    scrollFunction(force) {
      if (this.track._id != this.selectedTrack._id && force !== true) {
        return;
      }

      let tracklist = document.getElementById("trackList");
      if (!tracklist) {
        return;
      }
      let parent = tracklist.getBoundingClientRect();
      let element = document.getElementById(this.track._id);
      if (!element) {
        return;
      }
      let bounding = element.getBoundingClientRect();
      let scrollDown = bounding.top < parent.top;
      let scrollUp = bounding.bottom > parent.bottom;
      if (scrollDown || force) {
        this.$nextTick(() => {
          element.scrollIntoView({
            block: "start",
            behavior: "smooth",
          });
        });
      } else if (scrollUp) {
        this.$nextTick(() => {
          element.scrollIntoView({
            block: "end",
            behavior: "smooth",
          });
        });
      }
    },
  },
  computed: {
    duration() {
      let min = parseInt(this.track.duration / 60);
      let sec = parseInt(this.track.duration - min * 60);
      return min + ":" + (sec < 10 ? "0" : "") + sec;
    },
    selectedTrack() {
      return this.$store.getters["tracks/selectedTrack"];
    },
    cover() {
      let cover = this.track.covers ? this.track.covers.cover32 : undefined;
      if (!cover) {
        cover =
          this.track.parent.covers && this.track.parent.covers.cover64
            ? this.track.parent.covers.cover64
            : "/static/icons/dummy/album.svg";
      }
      return cover;
    },
  },
  watch: {
    selectedTrack() {
      this.scrollFunction();
    },
  },
};
</script>