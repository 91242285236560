export default {
    selectVideo(state, video) {
        state.selectedVideo = video;
    },
    resetSelectedVideo(state) {
        if (!state.selectedVideo._id) {
            return;
        }
        state.selectedVideo = { tracks: [], parent: { title: "", covers: {} } };
    },
    setMostViewed(state, tracks) {
        state.mostViewed = tracks;
    }
}