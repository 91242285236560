export default {
    collection(state) {
        return state.collection;
    },
    favourites(state, getters, rootState, rootGetters) {
        return state.collection.filter(f => rootGetters["user/favourites"].map(m => m.itemId).indexOf(f._id) > -1);
    },
    prevBox(state) {
        let currentIndex = state.collection.indexOf(state.selectedBox);
        let prevBox = {};
        if (currentIndex > 0) {
            prevBox = state.collection[currentIndex - 1];
        }
        return prevBox;
    },
    nextBox(state) {
        let currentIndex = state.collection.indexOf(state.selectedBox);
        let nextBox = {};
        if (state.collection.length > currentIndex + 1) {
            nextBox = state.collection[currentIndex + 1];
        }
        return nextBox;
    },
    selectedBox(state) {
        return state.selectedBox;
    },
    loading(state) {
        return state.loading;
    },
    eos(state) {
        return state.eos;
    },
    newest(state) {
        return state.newest;
    }
}