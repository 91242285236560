import router from '../router'

export default {
    headers(state, getters) {
        return { headers: { authorization: getters["user/token"] } };
    },
    server(state) {
        return state.clientConfig.server;
    },
    isElectron(state) {
        return state.clientConfig.isElectron;
    },
    isDialogOpen(state, getters) {
        return getters["albums/selectedAlbum"]._id || getters["artists/selectedArtist"]._id || getters["boxes/selectedBox"]._id
    },
    viewMenu(state, getters) {
        return state.viewMenu.filter(item => { return item.roles && getters["user/roles"].find(role => { return item.roles.indexOf(role) > -1 }) });
    },
    routerPath() {
        return router.currentRoute._value.path;
    },
    routerQuery() {
        return router.currentRoute._value.query;
    },
    serverInfo(state) {
        return state.serverInfo;
    },
    serverConfig(state) {
        return state.serverConfig;
    }
}