export default {
  switchPlayerRepeatMode(context) {
    switch (context.state.repeatType) {
      case "all":
        context.state.repeatType = "one";
        break;
      case "one":
        context.state.repeatType = "none";
        break;
      default:
        context.state.repeatType = "all";
        break;
    }
  },
  setRepeatType(context, type) {
    context.state.repeatType = type;
  },
  setRequestReplayTrack(context) {
    context.commit("requestReplayTrack");
  },
  toggleShuffleMode(context) {
    context.commit("toggleShuffleMode");

    let container = context.rootGetters["tracks/selectedTrackContainer"];
    let currentTrack = context.rootGetters["tracks/selectedTrack"];
    if (context.getters.shuffle && currentTrack._id) {
      this.dispatch("tracks/shuffle", { container });
    }
    else if (container.shuffledTracks) {
      delete container.shuffledTracks;
    }
  },
}