export default {
    repeatType(state) {
        return state.repeatType;
    },
    shuffle(state) {
        return state.shuffle;
    },
    isPlaying(state, getters, rootState, rootGetters) {
        return rootGetters["tracks/selectedTrack"]._id !== undefined || rootGetters["radios/selectedRadio"]._id !== undefined;
    },
    requestReplayTrack(state) {
        return state.requestReplayTrack;
    }
}