import axios from 'axios'

export default {
  loadShares(context) {
    axios.get(context.rootGetters.server + "/api/shares", context.rootGetters.headers).then((res) => {
      context.commit('setShares', res.data);
    });
  },
  get(context, id) {
    return new Promise((resolve) => {
      axios.get(context.rootGetters.server + "/api/shares/" + id, context.rootGetters.headers).then((res) => {
        if (!res.data._id) {
          resolve(res.data);
          return;
        }
        switch (res.data.object.type) {
          case "album":
            res.data.object.tracks.forEach(track => {
              track.parent = res.data.object;
              track.parentType = "album"
            });
            break;
          case "box":
            res.data.object.videos.forEach(video => {
              video.parent = res.data.object;
              video.parentType = "box"
            });
            break;
        }
        resolve(res.data);
      });
    });
  },
}