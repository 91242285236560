export default {
  clear(state) {
    state.collection = [];
    state.newest = [];
    state.loading = false;
    state.eos = false;
    state.page = 1;
  },
  remove(state, id) {
    let album = state.collection.find(f => f._id == id);
    if (album) {
      let i = state.collection.indexOf(album);
      state.collection.splice(i, 1);
    }
  },
  resetSelectedAlbum(state) {
    if (state.selectedAlbum._id)
      state.selectedAlbum = { tracks: [], covers: {} };
  },
  selectAlbum(state, album) {
    if (state.selectedAlbum._id != album._id) {
      state.selectedAlbum = album;
    }
  },
  setAlbums(state, albums) {
    if (albums.length == 0) {
      state.eos = true;
      state.loading = false;
      if (state.page > 1) {
        state.page--;
      }
      return;
    }
    albums.forEach(album => {
      let existsAlbum = state.collection.find(f => f._id == album._id);
      if (!existsAlbum) {
        let item = state.collection.find((item) => {
          if (item.artist_name > album.artist_name
            || item.artist_name == album.artist_name && item.year > album.year
            || item.artist_name == album.artist_name && item.year == album.year && item.title > album.title) {
            return item;
          }
        })

        if (!album.covers) {
          album.covers = {};
        }

        if (item) {
          let index = state.collection.indexOf(item);
          state.collection.splice(index, 0, album);
        } else {
          state.collection.push(album);
        }
        album.tracks.forEach((track) => {
          track.parent = album;
          track.parentType = "album"
        });

      } else if (existsAlbum && album.tracks.length > 0) {
        existsAlbum.covers = album.covers || {};
        existsAlbum.share = album.share;
        existsAlbum.tracks = album.tracks;
        existsAlbum.tracks.forEach((track) => {
          track.parent = existsAlbum;
          track.parentType = "album"
        });
      }
    });
    state.loading = false;
  },
  setNewest(state, albums) {
    albums.forEach(album => {
      if (!album.covers) {
        album.covers = {};
      }
    });
    state.newest = albums;
  },
  setRandomCovers(state, covers) {
    state.randomCovers = covers;
  }
}