import axios from 'axios'
import router from '../../../router'

export default {
  clear(context) {
    context.commit("clear");
  },
  loadRadios(context, force) {
    if (context.state.collection.length == 0 || force) {
      axios.get(context.rootGetters.server + "/api/radios", context.rootGetters.headers).then((res) => {
        context.commit("setRadios", res.data);
      });
    }
  },
  play(context, radio) {
    context.commit("selectRadio", radio);
    if (!context.rootGetters.routerQuery || context.rootGetters.routerQuery.play != radio._id) {
      router.push("/radios?play=" + radio._id);
    }
  },
  resetSelectedRadio(context) {
    context.commit("resetSelectedRadio");
    router.push("/radios");
  },
  addRadio(context, radio) {
    axios.post(context.rootGetters.server + "/api/radios", radio, context.rootGetters.headers).then(() => {
      context.dispatch("loadRadios", true);
    });

  },
  deleteRadio(context, radio) {
    axios.delete(context.rootGetters.server + "/api/radios?id=" + radio._id, context.rootGetters.headers).then(() => {
      context.dispatch("loadRadios", true);
    });
  },
  updateRadio(context, radio) {
    let input = document.createElement('input');
    input.type = "file";
    input.accept = "image/jpeg, image/png";
    input.addEventListener("change", function () {
      if (input.value) {
        let formData = new FormData();
        formData.append("file", input.files[0]);
        let h = context.rootGetters.headers;
        h.headers["content-type"] = "multipart/form-data";
        axios.put(context.rootGetters.server + "/api/radios/" + radio._id + "/cover", formData, h).then((res) => {
          radio.covers = res.data.covers;
          context.dispatch("loadRadios", true);
        });
      }
    });
    input.click();
  }
}