import axios from 'axios'
import router from '../../../router'

export default {
  addUser(context, newUser) {
    return new Promise((resolve) => {
      axios.post(context.rootGetters.server + "/api/user", newUser, context.rootGetters.headers).then(() => {
        context.dispatch("loadUsers");
        resolve();
      });
    })
  },
  addUserIfNotExists(context, newUser) {
    return new Promise((resolve) => {
      axios.get(context.rootGetters.server + "/api/user/" + newUser.name + "/exists", context.rootGetters.headers).then((res) => {
        if (res.data.exists) {
          resolve(false);
        } else {
          context.dispatch("addUser", newUser).then(() => {
            resolve(true)
          });
        }
      });
    });
  },
  deleteUser(context, user) {
    axios.delete(context.rootGetters.server + "/api/user?id=" + user._id, context.rootGetters.headers).then((res) => {
      if (res.data.length > 0) {
        context.commit("setUsers", res.data);
      } else {
        window.location.href = "/system/setup";
      }
    }).catch(err => {
      console.log(err.response.status);
    });
  },
  updateUser(context, user) {
    return new Promise((resolve) => {
      axios.put(context.rootGetters.server + "/api/user/", user, context.rootGetters.headers).then(() => {
        resolve();
      });
    });
  },
  loadUsers(context) {
    axios.get(context.rootGetters.server + "/api/user", context.rootGetters.headers).then((res) => {
      context.commit("setUsers", res.data);
    });
  },
  loadRoles(context) {
    return new Promise((resolve) => {
      axios.get(context.rootGetters.server + "/api/settings/user/roles", context.rootGetters.headers).then((res) => {
        context.commit("setRoles", res.data);
        resolve();
      });
    });
  },
  loadLists(context) {
    axios.get(context.rootGetters.server + "/api/settings/lists", context.rootGetters.headers).then((res) => {
      context.state.lists = res.data;
    });
  },
  createInstanceAccess(context, user) {
    axios
      .post(context.rootGetters.server + "/api/system/setup", user)
      .then((res) => {
        localStorage.setItem("token", res.data.token);
        router.replace("login");
      })
      .catch((err) => {
        console.log(err);
      });
  }
}