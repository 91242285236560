import axios from 'axios';
import router from '../../../router'

export default {
  convertNextTo(context, payload) {
    let next = getNextTrack(context, payload.track);
    if (next && next._id != payload.track._id) {
      axios.put(context.rootGetters.server + "/api/tracks/" + next._id + "/convert/" + payload.rate, context.rootGetters.headers);
    }
  },
  play(context, track) {
    track.skipTo = 0;
    track.percent = 0;
    context.commit("selectTrack", track);
    if (context.rootGetters.routerQuery.id == context.getters.selectedTrackContainer._id && context.rootGetters.routerQuery.play != track._id) {
      switch (track.parentType) {
        case "album":
          router.replace("/albums?id=" + track.parent._id + "&play=" + track._id);
          break;
        case "artist":
          router.replace("/artists?id=" + track.parent.parent._id + "&play=" + track._id);
          break;
      }
    }
    if (context.rootGetters["player/shuffle"]) {
      let container = context.getters.selectedTrackContainer;
      if (!container.shuffledTracks) {
        context.dispatch("shuffle", { container, track });
      }
    }
  },
  playContainer(context, container) {
    if (context.rootGetters["player/shuffle"]) {
      context.dispatch("shuffle", { container }).then(() => {
        context.dispatch("play", container.shuffledTracks[0]);
      });
    } else {
      context.dispatch("play", container.tracks[0]);
    }
  },
  playNextTo(context, track) {
    let next = getNextTrack(context, track);
    if (next) {
      context.dispatch("play", next);
    } else {
      context.commit("resetSelectedTrack");
    }
  },
  playPrevTo(context, track) {
    let currentTime = track.duration / 100 * track.percent;
    if (currentTime > 3) {
      this.dispatch("player/setRequestReplayTrack");
    } else {
      let prev = getPrevTrack(context, track);
      if (prev) {
        context.dispatch("play", prev);
      } else {
        context.commit("resetSelectedTrack");
      }
    }
  },
  skip(context) {
    context.commit("skip");
  },
  loadMostListened(context) {
    axios.get(context.rootGetters.server + "/api/tracks/most_listened", context.rootGetters.headers)
      .then((res) => {
        context.commit("setMostListened", res.data);
      });
  },
  upload(context, form) {
    let h = context.rootGetters.headers;
    h.headers["content-type"] = "multipart/form-data";
    return new Promise((resolve, reject) => {
      axios
        .post(context.rootGetters.server + "/api/tracks", form, h)
        .then(() => {
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  shuffle(context, payload) {
    let container = payload.container;
    let currentTrack = payload.track
    return new Promise((resolve) => {
      let shuffledTracks = [...container.tracks];
      let j, x, i;
      for (i = shuffledTracks.length - 1; i > 0; i--) {
        j = Math.floor(Math.random() * (i + 1));
        x = shuffledTracks[i];
        shuffledTracks[i] = shuffledTracks[j];
        shuffledTracks[j] = x;
      }
      if (currentTrack) {
        let currentIndex = shuffledTracks.indexOf(currentTrack);
        if (currentIndex > 0) {
          let tmp = shuffledTracks[currentIndex];
          shuffledTracks[currentIndex] = shuffledTracks[0];
          shuffledTracks[0] = tmp;
          currentIndex = shuffledTracks.indexOf(currentTrack);
        }
      }
      container.shuffledTracks = shuffledTracks;
      resolve();
    });
  }
}

function getNextTrack(context, track) {
  if (context.rootGetters["player/repeatType"] == "one") {
    return track;
  }
  let container = context.getters.selectedTrackContainer
  let tracks = container.shuffledTracks || container.tracks;
  let currentIndex = tracks.indexOf(track)
  if (currentIndex < tracks.length - 1) {
    return tracks[currentIndex + 1];
  } else if (context.rootGetters["player/repeatType"] == "all") {
    return tracks[0];
  }

  return undefined
}

function getPrevTrack(context, track) {
  let container = context.getters.selectedTrackContainer
  let tracks = container.shuffledTracks || container.tracks;
  let currentIndex = tracks.indexOf(track)
  if (currentIndex > 0) {
    return tracks[currentIndex - 1];
  }
  return undefined;
}