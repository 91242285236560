<template>
  <DialogBase
    ref="dialogWindow"
    :title="$store.state.user.name + '\'s Profile'"
    @closed="closed"
    :showCloseButton="false"
    :closeOnFocusLost="false"
    :closeOnEscape="false"
  >
    <div id="profileBody" class="ma">
      <table class="padding">
        <tr>
          <td colspan="2"><h4>Set new password</h4></td>
        </tr>
        <tr>
          <td colspan="2">
            <div id="newUserPass">
              <input
                autocomplete="off"
                type="password"
                ref="oldPass"
                v-model="oldPass"
                placeholder="Current Password"
              />

              <input
                autocomplete="off"
                type="password"
                ref="newPass"
                v-model="newPass"
                placeholder="New Password"
                @keydown.enter="changePassword"
              />
            </div>
          </td>
        </tr>
        <tr></tr>
        <tr>
          <td colspan="2"><h4 class="ma-top">Configuration</h4></td>
        </tr>
        <tr>
          <td>Clear history</td>
          <td>
            <button @click="clearHistory">clear</button>
          </td>
        </tr>
        <tr>
          <td colspan="2"><h4 class="ma-top">Audioquality</h4></td>
          <td colspan="2"><h4 class="ma-top">Video</h4></td>
        </tr>
        <tr>
          <td>On Moble Devices</td>
          <td>
            <select v-model="$store.getters['user/settings'].mobile_bpm">
              <option
                v-for="(item, i) in this.$store.state.system.lists
                  .audio_quality"
                :key="i"
              >
                {{ item }}
              </option>
            </select>
            <span>kBit/s</span>
          </td>
          <td>Preferred Language</td>
          <td class="fillCell">
            <select v-model="$store.getters['user/settings'].video_lang">
              <option
                v-for="(item, i) in this.$store.state.system.lists.video_lang"
                :key="i"
              >
                {{ item }}
              </option>
            </select>
          </td>
        </tr>
        <tr>
          <td>On Desktop Devices</td>
          <td>
            <select v-model="$store.getters['user/settings'].desktop_bpm">
              <option
                v-for="(item, i) in this.$store.state.system.lists
                  .audio_quality"
                :key="i"
              >
                {{ item }}
              </option>
            </select>
            <span>kBit/s</span>
          </td>
          <td>Quality</td>
          <td class="fillCell">
            <select v-model="$store.getters['user/settings'].video_quality">
              <option
                v-for="(item, i) in this.$store.state.system.lists
                  .video_quality"
                :key="i"
              >
                {{ item }}
              </option>
            </select>
          </td>
        </tr>
      </table>
    </div>
  </DialogBase>
</template>
<script>
export default {
  data() {
    return {
      loaded: false,
      oldPass: "",
      newPass: "",
    };
  },
  methods: {
    open() {
      if (!this.loaded) {
        this.$store.dispatch("system/loadLists");
        this.$store.state.systemDialog = true;
        this.loaded = true;
      }
      this.$refs.dialogWindow.open();
      this.$nextTick(() => {
        this.$refs.oldPass.focus();
      });
    },
    closed() {
      this.$store.dispatch("user/updateConfig");
      this.$store.state.systemDialog = false;
    },
    changePassword() {
      console.log("changePassword");
      let user = { oldPass: this.oldPass, newPass: this.newPass };
      this.$store
        .dispatch("user/update", user)
        .then((response) => {
          switch (response.status) {
            case 202:
              this.oldPass = "";
              this.newPass = "";
              this.$refs.dialogWindow.messageText = "Password changed";
              this.$refs.dialogWindow.focusButton();
              break;
          }
        })
        .catch((e) => {
          switch (e.status) {
            case 401:
              this.$refs.dialogWindow.messageText = "Not Authorized";
              break;
            case 422:
              this.$refs.oldPass.focus();
              this.$refs.oldPass.select();
              this.$refs.dialogWindow.messageText = "Password is incorrect";
              break;
          }
        });
    },

    clearHistory() {
      this.$store.dispatch("user/cleanHistory");
    },
  },
};
</script>
