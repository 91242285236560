<template>
  <div id="albumContent" class="flex-column">
    <div class="flex-column pa-horizontal border-bottom hideOnMobile">
      <h1>{{ album.title }}</h1>
      <h2>{{ album.artist_name }}</h2>
    </div>
    <div id="album" class="flex-row">
      <div id="header" class="flex-column relative">
        <div id="background" :style="coverBackground" />
        <img id="cover" class="shadow ma24 z1" :src="cover" />
        <p class="center ma-off hideOnMobile">
          <b>{{ album.tracks.length }}</b> Tracks
        </p>
      </div>
      <div id="tracks" class="flex-column grow">
        <ul id="trackList" class="tracks">
          <li v-for="track in album.tracks" :key="track._id">
            <TrackItem :track="track" :showCover="false" />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import TrackItem from "../components/Track";
export default {
  data() {
    return {
      album: {
        covers: {},
        tracks: [],
      },
    };
  },
  methods: {
    show(album) {
      this.album = album;
    },
  },
  computed: {
    cover() {
      if (!this.album.covers) {
        return "/static/icons/dummy/album.svg";
      }
      return this.album.covers.cover256;
    },
    coverBackground() {
      return "background-image: url('" + this.cover + "'); z-index: -1";
    },
  },
  components: {
    TrackItem,
  },
};
</script>

<style scoped>
#cover {
  align-self: center;
  width: 256px;
}

#albumContent {
  overflow: hidden;
  width: 100%;
}

#album {
  overflow: auto;
}

#header {
  overflow: hidden;
}

#tracks {
  background-color: var(--white);
  overflow-y: auto;
}

@media (max-width: 480px) {
  #album {
    flex-direction: column;
    max-height: 100%;
  }
  #tracks {
    border-top: 1px solid var(--light-border);
  }
}
</style>