<template>
  <div
    class="popup-control flex-column shadow ma8"
    :class="{ fadeOut: fadeout }"
  >
    <div class="flex-row" :class="item.type">
      <awesome-icon :icon="item.icon || 'circle-info'" size="2x" class="ma" />
      <div class="flex-column pa8-top ma8-bottom ma8-right">
        <h3 class="ma-off">{{ item.title }}</h3>
        <p class="ma-off ma8-top">{{ item.message }}</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      fadeout: false,
    };
  },
  props: {
    item: {
      type: Object,
      default() {
        return {
          type: "default",
        };
      },
    },
  },
  mounted() {
    setTimeout(() => {
      this.fadeout = true;
      setTimeout(() => {
        this.$store.dispatch("removePopUp", this.item);
      }, 1000);
    }, 4000);
  },
};
</script>

<style scoped>
.popup-control {
  width: 320px;
  border-radius: 8px;
  background-color: var(--white);
}

.default {
  border-radius: 8px;
  background-color: var(--white);
}

.danger {
  border-radius: 8px;
  background-color: var(--danger25);
}
.info {
  border-radius: 8px;
  background-color: var(--info);
}
.success {
  border-radius: 8px;
  background-color: var(--success);
}
.fadeOut {
  animation: fadeOut ease 1s;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
</style>