export default {
    serverStatus: { scanning_music: false, scanning_video: false },
    searchFilter: "",
    systemDialog: false,
    viewMenu: [],
    clientConfig: {
        server: "none",
        isElectron: false
    },
    serverConfig: { allows: {}, domains: [] },
    serverInfo: {
        stats: {
            albums: 0,
            tracks: 0,
            videos: 0,
            users: 0
        }
    },
    popups: []
}
