export default {
  load(state, user) {
    user.history.forEach(item => {
      item._id = item.id;
    })
    state.settings.mobile_bpm = user.mobile_bpm;
    state.settings.desktop_bpm = user.desktop_bpm;
    state.settings.video_lang = user.video_lang;
    state.settings.video_quality = user.video_quality;
    state.name = user.name;
    state.roles = user.roles;
    state.favourites = user.favourites;
    state.history = user.history;
    state._id = user._id;
  },
  resetToken(state) {
    state.token = "";
  },
  setToken(state, token) {
    state.token = token;
  },
  setHistory(state, history) {
    history.forEach(item => {
      item._id = item.id;
    });
    state.history = history;
  },
  setSettings(state, settings) {
    state.settings = settings;
  },
  setGuestAccount(state) {
    state.settings.mobile_bpm = 64;
    state.settings.desktop_bpm = 128;
    state.settings.video_lang = "ENG";
    state.settings.video_quality = "realtime";
    state.name = 'Guest';
    state.roles = ['guest'];
    state.history = [];
    state._id = "-1";
  }
}