<template>
  <div ref="radios" id="radios" @scroll="loadNextPage">
    <RadioItem v-for="(radio, i) in radios" :key="i" :item="radio" />
    <MessageScreen
      v-if="radios.length == 0"
      title="No Radios"
      subtitle="Import some Online Radios into your Library"
      icon="podcast"
      :commands="messageCommands"
      @commandClicked="addRadio"
      :showCommands="$store.getters['user/isAdministrator']"
    />
    <RadiosEditor ref="radiosEditor" />
  </div>
</template>
<script>
import RadioItem from "../components/Radio";
import RadiosEditor from "../components/dialogs/Radios";
import { mapGetters } from "vuex";

export default {
  name: "RadiosView",
  data() {
    return {
      scrollPosition: 0,
      messageCommands: [
        {
          title: "Add Online Radio",
          subtitle: "Add an url into your Library…",
          icon: "plus",
        },
      ],
      viewMenu: [
        {
          title: "Manage Radio Stations",
          icon: "podcast",
          roles: ["moderator", "admin"],
          event: this.addRadio,
        },
      ],
    };
  },
  mounted() {
    this.$store.dispatch("radios/loadRadios");
    this.$store.dispatch("setViewMenu", this.viewMenu);
  },
  computed: {
    ...mapGetters({ radios: "radios/collection" }),
  },
  methods: {
    loadNextPage() {
      this.scrollPosition = this.$refs.radios.scrollTop;
      this.$store.dispatch("radios/loadRadios");
    },
    addRadio() {
      this.$refs.radiosEditor.open();
    },
  },
  watch: {
    "$route.path": function (newVal) {
      if (newVal == "/radios") {
        this.$nextTick(() => {
          this.$refs.radios.scrollTop = this.scrollPosition;
          this.$store.dispatch("setViewMenu", this.viewMenu);
          if (!this.loading) {
            this.loadNextPage();
          }
        });
      }
    },
  },
  components: {
    RadioItem,
    RadiosEditor,
  },
};
</script>
