import axios from 'axios'
import router from '../../../router'

export default {
  clear(context) {
    context.commit("clear");
  },
  filter(context, term) {
    return new Promise((resolve) => {
      axios.get(context.rootGetters.server + "/api/artists/filter/" + term, context.rootGetters.headers).then(res => {
        resolve(res.data);
      });
    })
  },
  loadArtists(context, force) {
    return new Promise((resolve) => {
      if ((!context.state.eos || force) && !context.state.loading) {
        context.state.loading = true;
        axios.get(context.rootGetters.server + "/api/artists/page/" + context.state.page++, context.rootGetters.headers).then((res) => {
          context.commit("setArtists", res.data);
          resolve(res.data);
        });
      }
    });
  },
  loadArtist(context, id) {
    context.state.loading = true;
    return new Promise((resolve) => {
      axios.get(context.rootGetters.server + "/api/artists/" + id, context.rootGetters.headers).then((res) => {
        if (res.data != "") {
          context.commit("setArtists", [res.data]);
        } else {
          context.state.loading = false;
        }
        resolve(res.data);
      });
    });
  },
  loadFavourites(context) {
    axios.get(context.rootGetters.server + "/api/artists/favourites", context.rootGetters.headers).then(res => {
      if (res.data.length > 0) {
        context.commit("setArtists", res.data);
      }
    });
  },
  selectArtist(context, artist) {
    if (artist.albums.length == 0) {
      context.dispatch("loadArtist", artist._id)
    }
    context.commit('selectArtist', artist);
    if (context.rootGetters.routerQuery.id != artist._id) {
      let url = "/artists?id=" + artist._id;
      let track = context.rootGetters["tracks/selectedTrack"];
      if (track._id && track.parentType == "artist" && track.parent.parent._id == artist._id) {
        url += "&play=" + track._id
      }
      router.push(url);
    }

    // PRELOAD NEXT AND PREV ARTIST
    let next = context.getters.nextArtist;
    if (next._id && next.albums.length == 0) {
      context.dispatch("loadArtist", next._id);
    }
    let prev = context.getters.prevArtist;
    if (prev._id && prev.albums.length == 0) {
      context.dispatch("loadArtist", prev._id);
    }
  },
  selectArtistById(context, id) {
    let artist = context.getters.collection.find(item => item._id == id);
    if (artist && artist._id != context.getters.selectedArtist._id) {
      context.dispatch("selectArtist", artist);
    }
  },
  resetSelectedArtist(context) {
    context.commit("resetSelectedArtist");
    router.push("/artists");
  },
  move(context, payload) {
    return new Promise((resolve) => {
      axios.put(context.rootGetters.server + "/api/artists/" + payload.source + "/move", payload, context.rootGetters.headers).then(res => {
        resolve(res.data);
      });
    })
  },
  remove(context, id) {
    context.commit("remove", id);
  },
  gotoPrevArtist(context) {
    let prevArtist = context.getters.prevArtist;
    if (prevArtist._id) {
      context.dispatch("selectArtist", prevArtist);
    }
  },
  gotoNextArtist(context) {
    let nextArtist = context.getters.nextArtist;
    if (nextArtist._id) {
      context.dispatch("selectArtist", nextArtist);
    }
  },
  uploadNewCover(context, artist) {
    let input = document.createElement('input');
    input.type = "file";
    input.accept = "image/jpeg, image/png";
    input.addEventListener("change", function () {
      if (input.value) {
        let formData = new FormData();
        formData.append("file", input.files[0]);
        let h = context.rootGetters.headers;
        h.headers["content-type"] = "multipart/form-data";
        axios
          .put(context.rootGetters.server + "/api/artists/" + artist._id + "/cover", formData, context.rootGetters.headers)
          .then(res => {
            artist.covers = res.data;
          });
      }
    });
    input.click();
  },
  resetCover(context, artist) {
    axios.delete(context.rootGetters.server + "/api/artists/" + artist._id + "/cover", context.rootGetters.headers).then(() => {
      artist.covers = {};
    });
  },
}