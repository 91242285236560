export default {
    favourites: [],
    history: [],
    roles: [],
    token: "",
    name: "",
    settings: {
        mobile_bpm: 0,
        desktop_bpm: 0,
        video_lang: "ENG",
        video_quality: "480"
    }
}