import { createStore } from 'vuex'

import state from './state'
import getters from './getters'
import mutations from './mutations'
import actions from "./actions"

import albums from "./modules/albums/"
import artists from "./modules/artists/"
import boxes from "./modules/boxes/"
import radios from "./modules/radios/"
import tracks from "./modules/tracks/"
import player from "./modules/player"
import user from "./modules/user"
import videos from "./modules/videos"
import system from "./modules/system"
import search from "./modules/search"
import share from "./modules/share"

export default createStore({
    state,
    getters,
    mutations,
    actions,
    modules: {
        albums,
        artists,
        boxes,
        radios,
        player,
        tracks,
        user,
        videos,
        system, 
        search,
        share
    }
})