<template>
  <DialogBase
    ref="dialogWindow"
    title="Move all title..."
    buttonText="Move"
    buttonClass="accept"
    @accept="merge"
    @closed="closed"
    :closeOnButtonClick="false"
    :enableFooterButtons="acceptable"
  >
    <div class="flex-row" id="merge-content">
      <div class="flex-column">
        <h4 class="ma-left ma-top">From</h4>
        <AlbumItem class="ma" :item="source" />
      </div>
      <awesome-icon id="arrow-icon" icon="arrow-right" />
      <div class="flex-column">
        <DropDown class="ma-left ma-top">
          <input
            type="search"
            v-model="search"
            @search="searchTermChanged"
            placeholder="Into"
          />
          <template v-slot:dropdown-content>
            <div class="flex-column">
              <h3 class="ma" v-if="albums.length == 0">Enter an album title</h3>
              <AlbumItem
                class="ma8"
                v-for="album in albums"
                :key="album._id"
                :item="album"
                @click="select(album)"
                type="line"
              />
            </div>
          </template>
        </DropDown>
        <AlbumItem class="ma" :item="target" />
      </div>
    </div>
  </DialogBase>
</template>


<script>
export default {
  data() {
    return {
      albums: [],
      search: "",
      searchTimer: 0,
      source: {},
      target: { covers: {} },
    };
  },
  methods: {
    closed() {
      this.albums = [];
      this.source = {};
      this.target = { covers: {} };
      this.search = "";
      clearTimeout(this.searchTimer);
    },
    open(source) {
      this.source = source;
      this.$refs.dialogWindow.open();
    },
    merge() {
      this.$store
        .dispatch("albums/move", {
          source: this.source._id,
          target: this.target._id,
        })
        .then(() => {
          this.$store.dispatch("albums/loadAlbum", this.target._id);
          this.$store.dispatch("albums/selectAlbumById", this.target._id);
          this.$store.dispatch("albums/remove", this.source._id);
          this.$refs.dialogWindow.close();
        });
    },
    searchTermChanged() {
      clearTimeout(this.searchTimer);
      this.searchTimer = setTimeout(() => {
        this.$store.dispatch("albums/filter", this.search).then((result) => {
          this.albums = result;
        });
      }, 250);
    },
    select(album) {
      this.target = album;
    },
  },
  computed: {
    acceptable() {
      return this.source._id != undefined && this.target._id != undefined;
    },
  },
};
</script>

<style scoped>
input {
  width: 128px;
}
#arrow-icon {
  align-self: center;
}
#merge-content {
  align-items: flex-end;
}
</style>