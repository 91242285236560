export default {
    favourites(state) {
        return state.favourites;
    },
    history(state) {
        return state.history;
    },
    roles(state) {
        return state.roles;
    },
    settings(state) {
        return state.settings;
    },
    token(state) {
        return state.token;
    },
    user(state) {
        return state;
    },
    isGuest(state) {
        return state._id == -1;
    },
    isAdministrator(state) {
        return state.roles.includes("admin");
    },
    isModerator(state) {
        return state.roles.includes("admin") || state.roles.includes("moderator");
    },
}