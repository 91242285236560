<script>
export default {
  props: {
    item: { covers: {} },
  },
  methods: {
    scrollFunction() {
      let element = document.getElementById(this.item._id);
      let bounding = element.getBoundingClientRect();
      let scrollDown = bounding.top < 56;
      let scrollUp =
        bounding.top + bounding.height >
        (window.innerHeight || document.documentElement.clientHeight);
      if (scrollDown) {
        element.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      } else if (scrollUp) {
        element.scrollIntoView({
          behavior: "smooth",
          block: "end",
        });
      }
    },
  },
};
</script>
