<template>
  <div class="flex-column" style="max-height: 100%; height: 100%">
    <div id="favourites">
      <h2 class="ma-left ma-top ma4-bottom" v-if="albums.length > 0">Albums</h2>
      <div id="albums" class="flex-row">
        <AlbumItem
          class="ma"
          v-for="album in albums"
          :key="album._id"
          :item="album"
        />
      </div>
      <h2 class="ma-left ma-top ma4-bottom ma-right" v-if="artists.length > 0">
        <hr class="ma-bottom" v-if="albums.length > 0" />
        Artists
      </h2>
      <div id="artists" class="flex-row">
        <ArtistItem
          class="ma"
          v-for="artist in artists"
          :key="artist._id"
          :item="artist"
        />
      </div>
      <h2 class="ma-left ma-top ma4-bottom  ma-right" v-if="boxes.length > 0">
        <hr class="ma-bottom" v-if="albums.length > 0 || artists.length > 0" />
        Videos
      </h2>
      <div id="boxes" class="flex-row">
        <BoxItem class="ma" v-for="box in boxes" :key="box._id" :item="box" />
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "FavouritesView",
  data() {
    return {};
  },
  mounted() {
    this.$store.dispatch("albums/loadFavourites");
    this.$store.dispatch("artists/loadFavourites");
    this.$store.dispatch("boxes/loadFavourites");
  },
  methods: {},
  computed: {
    ...mapGetters({
      albums: ["albums/favourites"],
      artists: ["artists/favourites"],
      boxes: ["boxes/favourites"],
    }),
  },
};
</script>
<style scoped>
</style>