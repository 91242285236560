<template>
  <div class="flex-column" style="max-height: 100%; height: 100%">
    <MessageScreen
      title="Still no Audio content"
      subtitle="You still don't have any Audio content on your instance"
      icon="music"
      :commands="messageCommands"
      @commandClicked="sync"
      :showCommands="$store.getters['user/isAdministrator']"
      v-if="artists.length == 0 && !loading"
    />
    <ArtistViewer />
    <div
      ref="artists"
      id="artists"
      @scroll="loadNextPage"
      @resize="loadNextPage"
    >
      <ArtistItem
        class="ma"
        v-for="artist in artists"
        :key="artist._id"
        :item="artist"
        @click="openArtist(artist)"
      />
      <div
        id="artistsLoadingControl"
        class="loadingItem"
        @click="loadNextPage"
        v-if="!eos"
      >
        Loading next artists...
      </div>
    </div>
  </div>
</template>
<script>
import ArtistViewer from "../components/dialogs/ArtistViewer";

import { mapGetters } from "vuex";

export default {
  name: "ArtistsView",
  data() {
    return {
      scrollPosition: 0,
      messageCommands: [
        {
          title: "Scan for Audio files",
          subtitle: "Scann your server for audio files…",
          icon: "sync",
          command: "scan",
        },
      ],
      viewMenu: [
        {
          title: "Synchronize Music",
          icon: "sync-alt",
          roles: ["admin"],
          event: this.sync,
        },
      ],
    };
  },
  mounted() {
    if (this.$route.query.id) {
      this.$store.dispatch("artists/loadArtist", this.$route.query.id);
    } else {
      this.loadNextPage(true);
    }
    this.$store.dispatch("setViewMenu", this.viewMenu);
  },
  computed: {
    ...mapGetters({
      artists: ["artists/collection"],
      loading: ["artists/loading"],
      eos: ["artists/eos"],
    }),
  },
  methods: {
    openArtist(artist) {
      this.$store.dispatch("artists/selectArtist", artist);
    },
    loadNextPage(force) {
      this.scrollPosition = this.$refs.artists.scrollTop;
      if ((!this.eos && this.isLoadingControlVisible()) || force === true) {
        this.$store.dispatch("artists/loadArtists", force);
        this.$store.dispatch("setViewMenu", this.viewMenu);
      }
    },
    isLoadingControlVisible() {
      let element = document.getElementById("artistsLoadingControl");
      if (!element) {
        return false;
      }
      let bounding = element.getBoundingClientRect();

      let vVisible =
        bounding.top - 256 <=
          (window.innerHeight || document.documentElement.clientHeight) &&
        bounding.top > -256;
      return vVisible;
    },
    sync() {
      this.$store.dispatch("startScanningMusic");
      setTimeout(() => {
        this.loadNextPage(true);
      }, 3000);
    },
  },
  watch: {
    loading(newVal) {
      if (!newVal) {
        this.$nextTick(() => {
          this.loadNextPage();
        });
      }
    },
    "$route.path": function (newVal) {
      if (newVal == "/artists") {
        this.$nextTick(() => {
          this.$refs.artists.scrollTop = this.scrollPosition;

          this.$store.dispatch("setViewMenu", this.viewMenu);
          if (!this.loading) {
            this.loadNextPage();
          }
        });
      }
    },
    "$route.query": function (newVal) {
      if (this.$route.path == "/artists") {
        if (newVal.id) {
          if (this.$store.getters["artists/selectedArtist"]._id != newVal.id) {
            this.$store.dispatch("artists/selectArtistById", newVal.id);
          }
        } else {
          this.$store.commit("artists/resetSelectedArtist");
        }
      } else {
        this.$store.commit("artists/resetSelectedArtist");
      }
    },
  },
  components: {
    ArtistViewer,
  },
};
</script>
