<template>
  <DialogBase
    ref="dialogWindow"
    title="Radio Stations"
    :showCloseButton="false"
    @closed="closed"
    buttonText="close"
  >
    <template v-slot:header-right>
      <div id="newRadio">
        <input
          id="radioName"
          ref="newName"
          v-model="newName"
          type="text"
          placeholder="Station Name"
          required
          pattern=".+"
        />
        <input
          id="radioUrl"
          ref="newUrl"
          v-model="newUrl"
          type="text"
          placeholder="Add a stream url"
          required
          pattern="https?://.+"
          @keydown.enter="addRadio"
        />
        <button @click="addRadio" title="Add new Radio Station" class="success">
          <awesome-icon icon="plus" />
        </button>
      </div>
    </template>
    <div
      id="radiosBody"
      class="ma4-top ma4-left ma4-bottom"
      v-if="$store.getters['radios/collection'].length > 0"
    >
      <table>
        <thead>
          <tr>
            <th colspan="2">Name</th>
            <th class="maxWidth">Url</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(radio, i) in $store.getters['radios/collection']"
            :key="i"
          >
            <td>
              <img
                class="radioCover"
                :src="cover(radio)"
                @click="updateCover(radio)"
              />
            </td>
            <td>{{ radio.name }}</td>
            <td>{{ radio.url }}</td>
            <td>
              <button
                @click="deleteRadio(radio)"
                title="Remove Radio Station"
                class="flat"
              >
                <awesome-icon icon="trash-alt" />
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </DialogBase>
</template>
<script>
export default {
  name: "RadiosDialog",
  data() {
    return {
      newName: "",
      newUrl: "",
    };
  },
  methods: {
    open() {
      this.$store.state.systemDialog = true;
      this.$refs.dialogWindow.open();
      this.$nextTick(() => {
        this.$refs.newName.focus();
      });
    },
    closed() {
      this.$store.state.systemDialog = false;
    },
    isInputValid() {
      let inputs = document.querySelectorAll("#newRadio input");
      for (let i = 0; i < inputs.length; i++) {
        if (!inputs[i].validity.valid) {
          inputs[i].focus();
          return false;
        }
      }
      return true;
    },
    addRadio() {
      if (this.isInputValid()) {
        let newRadio = { name: this.newName, url: this.newUrl };
        this.$store.dispatch("radios/addRadio", newRadio);
      }
    },
    deleteRadio(radio) {
      this.$store.dispatch("radios/deleteRadio", radio);
    },
    updateCover(radio) {
      this.$store.dispatch("radios/updateRadio", radio);
    },
    cover(radio) {
      let res = "/static/icons/dummy/radio.svg";
      if (radio.covers.cover32) {
        res = radio.covers.cover32;
      }
      return res;
    },
  },
};
</script>