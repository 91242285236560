<template>
  <div :title="item.name" class="container radio ma" @click="clicked">
    <img class="radioCover shadow" :src="cover" />
    <p
      class="radioTitle"
      :class="{ selected: item == $store.state.selectedRadio }"
    >
      {{ item.name }}
    </p>
  </div>
</template>

<script>
import BaseCollection from "../mixins/BaseCollection";

export default {
  name: "RadioItem",
  mixins: [BaseCollection],
  mounted() {
    if (this.$route.query.play == this.item._id) {
      this.$store.dispatch("radios/play", this.item);
    }
  },
  methods: {
    clicked() {
      if (this.$route.path == "/" || this.$route.path == "/search") {
        this.$router.push("/radios?play=" + this.item._id);
      } else {
        this.$store.dispatch("radios/play", this.item);
      }
    },
  },
  computed: {
    cover() {
      let res = "/static/icons/dummy/radio.svg";
      if (this.item && this.item.covers.cover128) {
        res = this.item.covers.cover128;
      }
      return res;
    },
  },
};
</script>