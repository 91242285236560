<template>
  <div class="expander">
    <div class="expander-header" @click="expanded = !expanded">
      <awesome-icon icon="caret-up" size="2x" v-if="expanded" />
      <awesome-icon icon="caret-down" size="2x" v-else />
      <h3>{{title}}</h3>
    </div>
    <div class="expander-body" v-show="expanded">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name:"ExpanderControl",
  props: {
    title: {
      type: String,
      default: ""
    },
    expand: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      expanded: false
    };
  }
};
</script>