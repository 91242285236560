export default {
    collection(state) {
        return state.collection;
    },
    favourites(state, getters, rootState, rootGetters) {
        return state.collection.filter(f => rootGetters["user/favourites"].map(m => m.itemId).indexOf(f._id) > -1);
    },
    prevArtist(state) {
        let currentIndex = state.collection.indexOf(state.selectedArtist);
        let prevArtist = {};
        if (currentIndex > 0) {
            prevArtist = state.collection[currentIndex - 1];
        }
        return prevArtist;
    },
    nextArtist(state) {
        let currentIndex = state.collection.indexOf(state.selectedArtist);
        let nextArtist = {};
        if (state.collection.length > currentIndex + 1) {
            nextArtist = state.collection[currentIndex + 1];
        }
        return nextArtist;
    },
    selectedArtist(state) {
        return state.selectedArtist;
    },
    loading(state) {
        return state.loading;
    },
    eos(state) {
        return state.eos;
    }
}