export default {
    selectedVideo(state) {
        return state.selectedVideo;
    },
    getStreamUrl(state, getters, rootState, rootGetters) {
        return rootGetters.server + "/api/videos/" + state.selectedVideo._id + "/stream/" + (rootGetters["user/settings"].video_quality || "realtime") + "/"
    },
    mostViewed(state) {
        return state.mostViewed;
    }
}