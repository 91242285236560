export default {
  selectTrack(state, track) {
    state.selectedTrack = track;
  },
  resetSelectedTrack(state) {
    if (!state.selectedTrack._id) {
      return;
    }
    state.selectedTrack = { title: "", parent: { title: "", covers: {} } };
  },
  skip(state) {
    state.selectedTrack.skipTo = state.selectedTrack.percent;
  },
  setMostListened(state, tracks) {
    state.mostListened = tracks;
  }
}