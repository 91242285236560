<template>
  <DialogBase
    ref="dialogWindow"
    :closeOnButtonClick="false"
    :closeOnFocusLost="false"
    :maxSize="true"
    :enableFooterButtons="uploadable"
    title="Upload video files..."
    buttonText="Upload"
    buttonClass="success large"
    @accept="uploadFiles(0)"
    @closing="closing"
  >
    <div class="flex-row grow">
      <input
        style="display: none"
        ref="files"
        type="file"
        accept="video/*"
        @change="openFiles"
        multiple
      />
      <div class="flex-column" v-if="files.length > 0">
        <img
          class="boxCover shadow"
          :src="coverPreview || 'static/icons/dummy/box.svg'"
          @click="chooseCover"
        />
        <input
          type="text"
          class="ma"
          placeholder="box title"
          v-model="box.title"
        />
        <input type="text" class="ma" placeholder="year" v-model="box.year" />
      </div>
      <div class="flex-column grow">
        <ul id="videoUploadFiles">
          <li v-for="(file, i) in files" :key="i" class="pa-top">
            <h4 class="ma-left darkgray-text">{{ file.name }}</h4>
            <div class="flex-row ma">
              <awesome-icon
                :icon="file.state == 'uploading' ? 'upload' : 'check'"
                size="2x"
                :class="{
                  'success-text': file.state == 'done',
                  'gray-text': file.state == 'ready',
                  'primary-text': file.state == 'uploading',
                }"
              />
              <input
                placeholder="video title"
                type="text"
                v-model="file.tags.title"
                class="grow left pa8"
              />
              <button
                class="danger"
                title="Remove from list"
                @click="remove(file)"
              >
                <awesome-icon icon="minus" />
              </button>
            </div>
          </li>
        </ul>
        <div
          class="flex-column grow center ma primary-text"
          id="dropzone"
          @dragover="dropover"
          @drop="droped"
          @click="emitFileClick"
        >
          <h2>
            Drop your files here<br />
            <awesome-icon icon="plus" size="4x" />
          </h2>
        </div>
      </div>
    </div>
  </DialogBase>
</template>
<script>
export default {
  data() {
    return {
      box: { title: "", year: "" },
      files: [],
      coverPreview: "",
    };
  },
  methods: {
    open() {
      this.$refs.dialogWindow.open();
    },
    closing() {
      this.files = [];
    },
    chooseCover() {
      let me = this;
      let input = document.createElement("input");
      input.type = "file";
      input.accept = "image/jpeg, image/png";
      let reader = new FileReader();
      input.addEventListener("change", function () {
        if (input.value) {
          reader.readAsDataURL(input.files[0]);
          me.box.cover = input.files[0];
        }
      });
      reader.addEventListener("load", function () {
        me.coverPreview = this.result;
      });
      input.click();
    },
    dropover(e) {
      e.preventDefault();
      e.stopPropagation();
    },
    droped(e) {
      e.preventDefault();
      e.stopPropagation();
      let files = [];
      for (let i = 0; i < e.dataTransfer.files.length; i++) {
        let file = e.dataTransfer.files[i];
        if (file.type.indexOf("video/") == 0) {
          files.push({ file: file, state: "ready" });
        }
      }
      if (files.length > 0) {
        this.readFiles(files, 0);
      }
    },
    openFiles(e) {
      let files = [];
      if (e.srcElement.value) {
        for (let i = 0; i < e.srcElement.files.length; i++) {
          let file = e.srcElement.files[i];
          files.push({ file: file, state: "ready" });
        }
        this.readFiles(files, 0);
      }
    },
    readFiles(files, index) {
      let fileReader = new FileReader();
      var file = files[index];
      fileReader.onload = () => {
        file.tags = {};
        file.tags.title = file.file.name;
        this.files.push(file);
        if (files.length > index + 1) {
          this.readFiles(files, ++index);
        }
      };
      fileReader.readAsArrayBuffer(file.file);
    },
    emitFileClick() {
      this.$refs.files.click();
    },
    remove(file) {
      this.files.splice(this.files.indexOf(file), 1);
    },
    uploadFiles(index = 0) {
      if (this.files.length > index) {
        let file = this.files[index];
        if (file.state == "ready") {
          file.state = "uploading";
          let formData = new FormData();
          formData.append("file", file.file, "file");
          if (this.box.cover) {
            formData.append("file", this.box.cover, "cover");
            delete this.box.cover;
          }
          formData.append("title", file.tags.title || "");
          formData.append("box", JSON.stringify(this.box));
          this.$store
            .dispatch("videos/upload", formData)
            .then(() => {
              file.state = "done";
              this.uploadFiles(++index);
            })
            .catch((err) => {
              console.log(err);
              this.$refs.dialogWindow.messageText = err;
            });
        } else {
          this.uploadFiles(++index);
        }
      } else {
        this.$store.dispatch("boxes/loadNewest");
        this.$refs.dialogWindow.close();
      }
    },
  },
  computed: {
    uploadable() {
      return this.files.length > 0 && this.box.title.trim().length > 0;
    },
  },
};
</script>
<style scoped>
img {
  margin: 12px;
  width: 256px;
  height: 362px;
}
input[type="text"] {
  border: none;
  font-size: large;
}
#videoUploadFiles button {
  height: 32px;
  min-width: 32px;
  justify-content: center;
  align-self: center;
  border-radius: 16px;
}
#dropzone {
  border: 2px dashed var(--primary);
  cursor: pointer;
}
</style>