import { createWebHashHistory, createRouter } from "vue-router";

import LoginView from "./views/Login";
import AlbumsView from "./views/Albums";
import ArtistsView from "./views/Artists";
import RadiosView from "./views/Radios";
import BoxesView from "./views/Boxes";
import SearchView from "./views/Search";
import SetupView from "./views/Setup";
import ShareView from "./views/Share";
import HomeView from "./views/Home";
import UsersView from "./views/Users";
import FavouritesView from "./views/Favourites"

const routes = [
  {
    path: "/",
    component: HomeView
  },
  {
    path: "/login",
    component: LoginView
  },
  {
    path: "/albums",
    component: AlbumsView
  },
  {
    path: "/albums/:id",
    component: AlbumsView
  },
  {
    path: "/artists",
    component: ArtistsView
  },
  {
    path: "/artists/:id",
    component: ArtistsView
  },
  {
    path: "/radios",
    component: RadiosView
  },
  {
    path: "/boxes",
    component: BoxesView
  },
  {
    path: "/boxes/:id",
    component: BoxesView
  },
  {
    path: "/search",
    component: SearchView
  },
  {
    path: "/setup",
    component: SetupView
  },
  {
    path: "/share",
    component: ShareView
  },
  {
    path: "/me",
    component: UsersView
  },
  {
    path: "/favourites",
    component: FavouritesView
  }
]


const router = createRouter({
  history: createWebHashHistory(),
  routes
});

export default router;
