<template>
  <div id="login">
    <div id="loginViewer">
      <div id="loginLogin" class="flex-column">
        <img id="loginLogo" src="static/icon_64_flat.svg" />
        <h1>WebPlay</h1>
        <form id="loginForm" @submit.prevent="login" method="POST">
          <p>
            <input
              ref="username"
              name="username"
              type="text"
              placeholder="username"
              id="username"
            />
          </p>
          <p>
            <input
              ref="password"
              name="password"
              type="password"
              placeholder="password"
              id="password"
            />
          </p>
          <ExpanderControl
            title="Server configuration"
            v-if="$store.getters.isElectron"
          >
            <p>
              <input
                ref="backend"
                name="backend"
                type="url"
                placeholder="backend"
                id="backend"
                v-model.lazy="server"
                @change="backendChanged"
              />
            </p>
          </ExpanderControl>
          <button class="flat gray-border" action="submit">Login</button>
        </form>
        <p>{{ message }}</p>
      </div>
      <div
        id="loginContinue"
        class="flex-column"
        v-if="allow_guests || allow_registration"
      >
        <div id="loginGuest" class="flex-column" v-if="allow_guests">
          <button @click="continueAsGuest">
            <awesome-icon icon="music" class="ma" /><span class="ma4"
              >Continue as Guest</span
            >
          </button>
        </div>
        <p class="center ma pa-top" v-if="allow_guests && allow_registration">
          or
        </p>
        <div
          id="loginRegestration"
          class="flex-column ma-right ma-left"
          v-if="allow_registration"
        >
          <ExpanderControl title="Register now" class="ma-right ma-left">
            <form autocomplete="off" method="POST">
              <p>
                <input
                  type="email"
                  placeholder="Email adress"
                  v-model="newUserAcc.email"
                  autocomplete="one-time-code"
                />
              </p>
              <p>
                <input
                  type="text"
                  v-model="newUserAcc.name"
                  placeholder="Account"
                  autocomplete="one-time-code"
                />
              </p>
              <p>
                <input
                  type="password"
                  placeholder="Password"
                  v-model="newUserAcc.pass"
                  autocomplete="one-time-code"
                  pattern=".{5,}"
                />
              </p>
              <button class="flat gray-border" action="submit">Register</button>
            </form>
          </ExpanderControl>
        </div>
      </div>
    </div>
    <div id="loginFooter" class="ma">
    create your own <a href="https://code.anufrij.de/WebPlay/docker#setup-your-own-webplay-instance" target="webplay">WebPlay</a> instance
    </div>
  </div>
</template>

<script>
import ExpanderControl from "../components/base-components/Expander";

export default {
  name: "LoginView",
  data() {
    return {
      message: "",
      finished_checks: false,
      newUserAcc: { name: "", email: "", pass: "" },
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.username.focus();
    });
  },
  methods: {
    checkTocken() {
      let token = localStorage.getItem("token");
      if (token) {
        this.$store.dispatch("user/setToken", token);
        this.$store
          .dispatch("user/load")
          .then(() => {
            this.goto();
          })
          .catch((err) => {
            console.log(err);
            this.checkIfNewBackend();
          });
      } else {
        this.checkIfNewBackend();
      }
    },
    login() {
      this.$store
        .dispatch("user/login", {
          username: this.$refs.username.value,
          password: this.$refs.password.value,
        })
        .then(() => {
          this.goto();
        })
        .catch((err) => {
          if (err.status == 401) {
            this.message = "Login failed…";
          } else {
            this.message = "Server error…";
          }
          this.$refs.password.focus();
          this.$refs.password.select();
        });
    },
    checkIfNewBackend() {
      this.$store.dispatch("checkIfInstanceIsNew").then((res) => {
        if (res) {
          this.$router.replace("setup");
        } else if (this.$route.query.redirect) {
          this.continueAsGuest();
        }
        this.finished_checks = true;
      });
    },
    continueAsGuest() {
      if (this.allow_guests) {
        this.$store.dispatch("user/useGuestAccount").then(() => {
          this.goto();
        });
      }
    },
    goto() {
      if (this.$route.query.redirect) {
        let redirect = decodeURIComponent(this.$route.query.redirect);
        let route = redirect.split("?");
        if (route[1]) {
          let urlParams = new URLSearchParams(route[1]);
          let params = Object.fromEntries(urlParams);
          this.$router.replace({ path: route[0], query: params });
        } else {
          this.$router.replace(route[0]);
        }
      } else {
        this.$router.replace("/");
      }
    },
    backendChanged() {
      this.$store.dispatch("setNewBackend", this.$refs.backend.value);
    },
  },
  computed: {
    server() {
      return this.$store.getters.server;
    },
    allow_guests() {
      return this.$store.state.serverConfig.allows.guests;
    },
    allow_registration() {
      return this.$store.state.serverConfig.allows.register;
    },
  },
  watch: {
    server() {
      this.checkTocken();
    },
    allow_guests(newVal) {
      if (newVal && this.finished_checks && this.$route.query.redirect) {
        this.continueAsGuest();
      }
    },
    "$route.path": function (newVal) {
      if (newVal == "/artists") {
        this.$nextTick(() => {
          this.$refs.username.focus();
        });
      }
    },
  },
  components: { ExpanderControl },
};
</script>

<style scoped>
#loginFooter {

  position: absolute;
  bottom: 0;
} 

#loginFooter a {
  display: initial;
  color: var(--white);
  font-weight: bold;
}
</style>