export default {
    selectedTrack(state) {
        return state.selectedTrack;
    },
    selectedTrackContainer(state) {
        if (state.selectedTrack.parent.parent && state.selectedTrack.parent.parent.tracks) {
            return state.selectedTrack.parent.parent
        }
        return state.selectedTrack.parent;
    },
    mostListened(state) {
        return state.mostListened;
    }
}