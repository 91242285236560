<template>
  <div
    :id="video._id"
    class="video"
    :title="video.title"
    @click="clicked"
    :class="{ selected: video == $store.state.selectedVideo }"
  >
    <img class="videoCover shadow" :src="video.thumbnail" />
    <div class="videoTitle componentTitle">{{ video.title }}</div>
  </div>
</template>

<script>
export default {
  name: "VideoItem",
  props: {
    video: {
      type: Object,
    },
  },
  methods: {
    clicked() {
      if (this.$route.path == "/" || this.$route.path == "/search") {
        this.$store.dispatch("boxes/loadBox", this.video.parent._id);
        this.$router.push(
          "/boxes?id=" + this.video.parent._id + "&play=" + this.video._id
        );
      } else {
        this.$store.dispatch("videos/play", this.video);
      }
    },
    scrollFunction(video) {
      if (video._id != this.video._id) {
        return;
      }
      this.$nextTick(() => {
        let parent = document
          .getElementById("videoList")
          .getBoundingClientRect();
        let element = document.getElementById(this.video._id);
        let bounding = element.getBoundingClientRect();
        let scrollDown = bounding.top < parent.top;
        let scrollUp = bounding.bottom > parent.bottom;
        if (scrollDown) {
          element.scrollIntoView({
            block: "start",
            behavior: "smooth",
          });
        } else if (scrollUp) {
          element.scrollIntoView({
            block: "end",
            behavior: "smooth",
          });
        }
      });
    },
  },
  computed: {
    selectedVideo() {
      return this.$store.getters["selectedVideo"];
    },
  },
};
</script>