<template>
  <div class="flex-row pa">
    <div class="flex-column">
      <img id="profile" src="/static/icons/dummy/artist.svg" alt="" />
      <h2>{{ me.name }}</h2>
      <input type="text" class="h1 borderless" v-model="me.fullname" />

      <h3>Prefered Language</h3>
      <select v-model="$store.getters['user/settings'].video_lang">
        <option
          v-for="(item, i) in this.$store.state.system.lists.video_lang"
          :key="i"
        >
          {{ item }}
        </option>
      </select>
      <h3>Video quality</h3>
      <select v-model="$store.getters['user/settings'].video_quality">
        <option
          v-for="(item, i) in this.$store.state.system.lists.video_quality"
          :key="i"
        >
          {{ item }}
        </option>
      </select>
      <h3>Audio quality [kBin/s]</h3>
      <select v-model="$store.getters['user/settings'].desktop_bpm">
        <option
          v-for="(item, i) in this.$store.state.system.lists.audio_quality"
          :key="i"
        >
          {{ item }}
        </option>
      </select>
    </div>
    <div class="flex-column">
      <h3>My Music</h3>
      <div class="albums"></div>
      <h3>My Videos</h3>
      <div class="boxes"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UsersView",
  data() {
    return {};
  },
  computed: {
    me() {
      return this.$store.state.user;
    },
  },
};
</script>

<style scoped>
img#profile {
  width: 512px;
  max-width: 100%;
}
</style>